import { ThemeUIStyleObject } from 'theme-ui'

export { buttons } from './buttons'
export { icons } from './icon-styles'
export { text } from './text'
export const visuallyhidden:ThemeUIStyleObject = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
  whiteSpace: 'nowrap',
}
export const a:ThemeUIStyleObject = {
  default: {
    textDecoration: 'underline',
    color: 'primary',
  },
}
