import { ThemeUIStyleObject } from "theme-ui";

export const icons: Record<string,ThemeUIStyleObject> = {
  spinner: {
    WebkitAnimation: 'spin 4s linear infinite',
    MozAnimation: 'spin 4s linear infinite',
    animation: 'spin 4s linear infinite',
    AnimationTimingFunction: 'steps(8, end)',
  },
}
