import React, {
  createContext,
  Dispatch,
  Fragment,
  useEffect,
  useState,
  useContext,
  SetStateAction,
} from "react";
import { Brands, StateProductPivot } from "../types.d";

export interface ProductContextConfig {
  country: "US" | "CA";
  brand: Brands;
}

export interface ProductContextState extends ProductContextConfig {
  loading: boolean;
  loaded: boolean;
  products?: StateProductPivot[];
  byState?: ProductState[];
}

export interface ProductState {
  name: string;
  url: string;
  products: StateProductPivot[];
}

const capFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();

const buildStateListFromProducts = (
  data: StateProductPivot[]
): ProductState[] =>
  data.reduce((res: ProductState[], node: StateProductPivot) => {
    const stateName = capFirstLetter(node.state?.name ?? "");
    let state = res.find((s) => s.name == stateName);
    if (!state) {
      state = {
        name: stateName,
        products: [],
        url: stateName.replace(" ", "-").toLowerCase(),
      } as ProductState;
      res.push(state);
    }
    state.products.push(node);
    return res;
  }, [] as ProductState[]) ?? ([] as ProductState[]);

const initialState: ProductContextState = {
  loading: false,
  loaded: false,
  products: [],
  brand: Brands.Speedycash,
  country: "US",
};
interface ProductContextValue {
  state: ProductContextState;
  setState: Dispatch<SetStateAction<ProductContextState>>;
}
const ProductContext = createContext<ProductContextValue>({
  state: initialState,
  setState: () => null,
});

const ProductProvider: React.FC<
  ProductContextState & { children: React.ReactNode }
> = ({ children, ...config }) => {
  const [state, setState] = useState<ProductContextState>({
    ...initialState,
    ...config,
  });
  return (
    <ProductContext.Provider value={{ state, setState }}>
      {children}
    </ProductContext.Provider>
  );
};

const data = {
  stateProducts: {
    edges: [
      {
        node: {
          id: 236,
          state: {
            name: "Ontario",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 1500,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: "/loans/ontario/toronto-payday-loans",
          isStore: true,
          isWeb: true,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 267,
          state: {
            name: "Ontario",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 367,
          state: {
            name: "Ontario",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 239,
          state: {
            name: "Nova Scotia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 1500,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 299,
          state: {
            name: "Nova Scotia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: null,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 299,
          state: {
            name: "Nova Scotia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 245,
          state: {
            name: "Alberta",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 246,
          state: {
            name: "Alberta",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 251,
          state: {
            name: "Manitoba",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: null,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: null,
          isStore: true,
          isWeb: false,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },

      {
        node: {
          id: 307,
          state: {
            name: "Manitoba",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: null,
          storeMinAmount: 1000,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: false,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 308,
          state: {
            name: "Manitoba",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: false,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 237,
          state: {
            name: "British Columbia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 1500,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 289,
          state: {
            name: "British Columbia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 289,
          state: {
            name: "British Columbia",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 291,
          state: {
            name: "New Brunswick",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 247,
          state: {
            name: "New Brunswick",
            disabledAt: "3199-01-01T00:00:00.000-06:00",
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 238,
          state: {
            name: "Saskatchewan",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 1500,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 293,
          state: {
            name: "Saskatchewan",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: 1000,
          webMaxAmount: 10000,
          storeMinAmount: 1000,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 293,
          state: {
            name: "Saskatchewan",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 313,
          state: {
            name: "Newfoundland and Labrador",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Payday Loans",
            cashMoneyUrl: "payday-loans/",
            speedyCashUrl: "payday-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 1500,
          storeMinAmount: null,
          storeMaxAmount: 1500,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "We provide short\u0026ndash;term loans with a flat fee for emergency expenses. Simply pay us back on your next payday.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 314,
          state: {
            name: "Newfoundland and Labrador",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Line of Credit Loans",
            cashMoneyUrl: "line-of-credit/",
            speedyCashUrl: "line-of-credit",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Just apply once, then access additional cash anytime. We offer scheduled payments during your loan term. And you only pay interest on the amount you use.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
      {
        node: {
          id: 314,
          state: {
            name: "Newfoundland and Labrador",
            disabledAt: null,
            __typename: "State",
          },
          product: {
            productName: "Auto Equity Loans",
            cashMoneyUrl: "auto-equity-loans/",
            speedyCashUrl: "auto-equity-loans",
            disabledAt: null,
            __typename: "Product",
          },
          webMinAmount: null,
          webMaxAmount: 10000,
          storeMinAmount: null,
          storeMaxAmount: 10000,
          url: null,
          isStore: true,
          isWeb: true,
          productText:
            "Secure a loan with your vehicle and get approved for more credit! Gather the required documentation and drive your vehicle to a Cash Money location.",
          disabledAt: null,
          __typename: "StateProductPivot",
        },
        __typename: "StateProductPivotEdge",
      },
    ],
    __typename: "StateProductPivotConnection",
  },
};

const ProductLoader: React.FC = () => {
  const { state, setState } = useContext(ProductContext);
  useEffect(() => {
    const products = (
      data?.stateProducts?.edges?.map(
        (edge) => edge.node as StateProductPivot
      ) ?? ([] as StateProductPivot[])
    ).filter(
      (s) =>
        (!s.state?.disabledAt || new Date(s.state?.disabledAt) > new Date()) &&
        (!s.disabledAt || new Date(s.disabledAt) > new Date()) &&
        (!s.product?.disabledAt ||
          new Date(s.product?.disabledAt) > new Date()) &&
        (s.webMaxAmount || s.storeMaxAmount)
    );
    setState((state) => ({
      ...state,
      loading: false,
      loaded: true,
      products,
      byState: buildStateListFromProducts(products),
    }));
  }, [data]);
  return null;
};

const ProductConsumer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { state } = useContext(ProductContext);
  return (
    <Fragment>
      {!state.loaded && state.brand && <ProductLoader />}
      {children}
    </Fragment>
  );
};

export { ProductContext, ProductProvider, ProductConsumer };
