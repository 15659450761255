export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any
  PaginationAmount: any
}

export type Article = {
  __typename?: 'Article'
  author?: Maybe<Author>
  authorId?: Maybe<Scalars['Int']>
  brand: Brands
  category?: Maybe<Category>
  categoryId?: Maybe<Scalars['Int']>
  contentHtml?: Maybe<Scalars['String']>
  createdDate: Scalars['DateTime']
  defaultImg?: Maybe<Scalars['String']>
  defaultImgAltText?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  disable: Scalars['Boolean']
  feature?: Maybe<Scalars['String']>
  iD: Scalars['Int']
  images?: Maybe<Array<Maybe<BlogImage>>>
  markdown: Scalars['Boolean']
  modifiedDate: Scalars['DateTime']
  preview?: Maybe<Scalars['String']>
  previewImg?: Maybe<Scalars['String']>
  previewImgAltText?: Maybe<Scalars['String']>
  priority: Scalars['Int']
  publishedDate: Scalars['DateTime']
  sEOTitle?: Maybe<Scalars['String']>
  theme?: Maybe<Theme>
  themeId?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  urlTail?: Maybe<Scalars['String']>
  votedDown: Scalars['Int']
  votedUp: Scalars['Int']
}

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection'
  /** A list of edges. */
  edges?: Maybe<Array<ArticleEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Article>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Article>
}

export type ArticleFilter = {
  AND?: Maybe<Array<ArticleFilter>>
  author?: Maybe<AuthorFilter>
  authorId?: Maybe<Scalars['Int']>
  authorId_gt?: Maybe<Scalars['Int']>
  authorId_gte?: Maybe<Scalars['Int']>
  authorId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  authorId_lt?: Maybe<Scalars['Int']>
  authorId_lte?: Maybe<Scalars['Int']>
  authorId_not?: Maybe<Scalars['Int']>
  authorId_not_gt?: Maybe<Scalars['Int']>
  authorId_not_gte?: Maybe<Scalars['Int']>
  authorId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  authorId_not_lt?: Maybe<Scalars['Int']>
  authorId_not_lte?: Maybe<Scalars['Int']>
  brand?: Maybe<Brands>
  brand_gt?: Maybe<Brands>
  brand_gte?: Maybe<Brands>
  brand_in?: Maybe<Array<Brands>>
  brand_lt?: Maybe<Brands>
  brand_lte?: Maybe<Brands>
  brand_not?: Maybe<Brands>
  brand_not_gt?: Maybe<Brands>
  brand_not_gte?: Maybe<Brands>
  brand_not_in?: Maybe<Array<Brands>>
  brand_not_lt?: Maybe<Brands>
  brand_not_lte?: Maybe<Brands>
  category?: Maybe<CategoryFilter>
  categoryId?: Maybe<Scalars['Int']>
  categoryId_gt?: Maybe<Scalars['Int']>
  categoryId_gte?: Maybe<Scalars['Int']>
  categoryId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  categoryId_lt?: Maybe<Scalars['Int']>
  categoryId_lte?: Maybe<Scalars['Int']>
  categoryId_not?: Maybe<Scalars['Int']>
  categoryId_not_gt?: Maybe<Scalars['Int']>
  categoryId_not_gte?: Maybe<Scalars['Int']>
  categoryId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  categoryId_not_lt?: Maybe<Scalars['Int']>
  categoryId_not_lte?: Maybe<Scalars['Int']>
  contentHtml?: Maybe<Scalars['String']>
  contentHtml_contains?: Maybe<Scalars['String']>
  contentHtml_ends_with?: Maybe<Scalars['String']>
  contentHtml_in?: Maybe<Array<Maybe<Scalars['String']>>>
  contentHtml_not?: Maybe<Scalars['String']>
  contentHtml_not_contains?: Maybe<Scalars['String']>
  contentHtml_not_ends_with?: Maybe<Scalars['String']>
  contentHtml_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  contentHtml_not_starts_with?: Maybe<Scalars['String']>
  contentHtml_starts_with?: Maybe<Scalars['String']>
  createdDate?: Maybe<Scalars['DateTime']>
  createdDate_gt?: Maybe<Scalars['DateTime']>
  createdDate_gte?: Maybe<Scalars['DateTime']>
  createdDate_in?: Maybe<Array<Scalars['DateTime']>>
  createdDate_lt?: Maybe<Scalars['DateTime']>
  createdDate_lte?: Maybe<Scalars['DateTime']>
  createdDate_not?: Maybe<Scalars['DateTime']>
  createdDate_not_gt?: Maybe<Scalars['DateTime']>
  createdDate_not_gte?: Maybe<Scalars['DateTime']>
  createdDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdDate_not_lt?: Maybe<Scalars['DateTime']>
  createdDate_not_lte?: Maybe<Scalars['DateTime']>
  defaultImg?: Maybe<Scalars['String']>
  defaultImgAltText?: Maybe<Scalars['String']>
  defaultImgAltText_contains?: Maybe<Scalars['String']>
  defaultImgAltText_ends_with?: Maybe<Scalars['String']>
  defaultImgAltText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defaultImgAltText_not?: Maybe<Scalars['String']>
  defaultImgAltText_not_contains?: Maybe<Scalars['String']>
  defaultImgAltText_not_ends_with?: Maybe<Scalars['String']>
  defaultImgAltText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defaultImgAltText_not_starts_with?: Maybe<Scalars['String']>
  defaultImgAltText_starts_with?: Maybe<Scalars['String']>
  defaultImg_contains?: Maybe<Scalars['String']>
  defaultImg_ends_with?: Maybe<Scalars['String']>
  defaultImg_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defaultImg_not?: Maybe<Scalars['String']>
  defaultImg_not_contains?: Maybe<Scalars['String']>
  defaultImg_not_ends_with?: Maybe<Scalars['String']>
  defaultImg_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defaultImg_not_starts_with?: Maybe<Scalars['String']>
  defaultImg_starts_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  disable?: Maybe<Scalars['Boolean']>
  disable_not?: Maybe<Scalars['Boolean']>
  feature?: Maybe<Scalars['String']>
  feature_contains?: Maybe<Scalars['String']>
  feature_ends_with?: Maybe<Scalars['String']>
  feature_in?: Maybe<Array<Maybe<Scalars['String']>>>
  feature_not?: Maybe<Scalars['String']>
  feature_not_contains?: Maybe<Scalars['String']>
  feature_not_ends_with?: Maybe<Scalars['String']>
  feature_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  feature_not_starts_with?: Maybe<Scalars['String']>
  feature_starts_with?: Maybe<Scalars['String']>
  iD?: Maybe<Scalars['Int']>
  iD_gt?: Maybe<Scalars['Int']>
  iD_gte?: Maybe<Scalars['Int']>
  iD_in?: Maybe<Array<Scalars['Int']>>
  iD_lt?: Maybe<Scalars['Int']>
  iD_lte?: Maybe<Scalars['Int']>
  iD_not?: Maybe<Scalars['Int']>
  iD_not_gt?: Maybe<Scalars['Int']>
  iD_not_gte?: Maybe<Scalars['Int']>
  iD_not_in?: Maybe<Array<Scalars['Int']>>
  iD_not_lt?: Maybe<Scalars['Int']>
  iD_not_lte?: Maybe<Scalars['Int']>
  images_all?: Maybe<BlogImageFilter>
  images_any?: Maybe<Scalars['Boolean']>
  images_none?: Maybe<BlogImageFilter>
  images_some?: Maybe<BlogImageFilter>
  markdown?: Maybe<Scalars['Boolean']>
  markdown_not?: Maybe<Scalars['Boolean']>
  modifiedDate?: Maybe<Scalars['DateTime']>
  modifiedDate_gt?: Maybe<Scalars['DateTime']>
  modifiedDate_gte?: Maybe<Scalars['DateTime']>
  modifiedDate_in?: Maybe<Array<Scalars['DateTime']>>
  modifiedDate_lt?: Maybe<Scalars['DateTime']>
  modifiedDate_lte?: Maybe<Scalars['DateTime']>
  modifiedDate_not?: Maybe<Scalars['DateTime']>
  modifiedDate_not_gt?: Maybe<Scalars['DateTime']>
  modifiedDate_not_gte?: Maybe<Scalars['DateTime']>
  modifiedDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  modifiedDate_not_lt?: Maybe<Scalars['DateTime']>
  modifiedDate_not_lte?: Maybe<Scalars['DateTime']>
  OR?: Maybe<Array<ArticleFilter>>
  preview?: Maybe<Scalars['String']>
  previewImg?: Maybe<Scalars['String']>
  previewImgAltText?: Maybe<Scalars['String']>
  previewImgAltText_contains?: Maybe<Scalars['String']>
  previewImgAltText_ends_with?: Maybe<Scalars['String']>
  previewImgAltText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  previewImgAltText_not?: Maybe<Scalars['String']>
  previewImgAltText_not_contains?: Maybe<Scalars['String']>
  previewImgAltText_not_ends_with?: Maybe<Scalars['String']>
  previewImgAltText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  previewImgAltText_not_starts_with?: Maybe<Scalars['String']>
  previewImgAltText_starts_with?: Maybe<Scalars['String']>
  previewImg_contains?: Maybe<Scalars['String']>
  previewImg_ends_with?: Maybe<Scalars['String']>
  previewImg_in?: Maybe<Array<Maybe<Scalars['String']>>>
  previewImg_not?: Maybe<Scalars['String']>
  previewImg_not_contains?: Maybe<Scalars['String']>
  previewImg_not_ends_with?: Maybe<Scalars['String']>
  previewImg_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  previewImg_not_starts_with?: Maybe<Scalars['String']>
  previewImg_starts_with?: Maybe<Scalars['String']>
  preview_contains?: Maybe<Scalars['String']>
  preview_ends_with?: Maybe<Scalars['String']>
  preview_in?: Maybe<Array<Maybe<Scalars['String']>>>
  preview_not?: Maybe<Scalars['String']>
  preview_not_contains?: Maybe<Scalars['String']>
  preview_not_ends_with?: Maybe<Scalars['String']>
  preview_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  preview_not_starts_with?: Maybe<Scalars['String']>
  preview_starts_with?: Maybe<Scalars['String']>
  priority?: Maybe<Scalars['Int']>
  priority_gt?: Maybe<Scalars['Int']>
  priority_gte?: Maybe<Scalars['Int']>
  priority_in?: Maybe<Array<Scalars['Int']>>
  priority_lt?: Maybe<Scalars['Int']>
  priority_lte?: Maybe<Scalars['Int']>
  priority_not?: Maybe<Scalars['Int']>
  priority_not_gt?: Maybe<Scalars['Int']>
  priority_not_gte?: Maybe<Scalars['Int']>
  priority_not_in?: Maybe<Array<Scalars['Int']>>
  priority_not_lt?: Maybe<Scalars['Int']>
  priority_not_lte?: Maybe<Scalars['Int']>
  publishedDate?: Maybe<Scalars['DateTime']>
  publishedDate_gt?: Maybe<Scalars['DateTime']>
  publishedDate_gte?: Maybe<Scalars['DateTime']>
  publishedDate_in?: Maybe<Array<Scalars['DateTime']>>
  publishedDate_lt?: Maybe<Scalars['DateTime']>
  publishedDate_lte?: Maybe<Scalars['DateTime']>
  publishedDate_not?: Maybe<Scalars['DateTime']>
  publishedDate_not_gt?: Maybe<Scalars['DateTime']>
  publishedDate_not_gte?: Maybe<Scalars['DateTime']>
  publishedDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  publishedDate_not_lt?: Maybe<Scalars['DateTime']>
  publishedDate_not_lte?: Maybe<Scalars['DateTime']>
  sEOTitle?: Maybe<Scalars['String']>
  sEOTitle_contains?: Maybe<Scalars['String']>
  sEOTitle_ends_with?: Maybe<Scalars['String']>
  sEOTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  sEOTitle_not?: Maybe<Scalars['String']>
  sEOTitle_not_contains?: Maybe<Scalars['String']>
  sEOTitle_not_ends_with?: Maybe<Scalars['String']>
  sEOTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  sEOTitle_not_starts_with?: Maybe<Scalars['String']>
  sEOTitle_starts_with?: Maybe<Scalars['String']>
  theme?: Maybe<ThemeFilter>
  themeId?: Maybe<Scalars['Int']>
  themeId_gt?: Maybe<Scalars['Int']>
  themeId_gte?: Maybe<Scalars['Int']>
  themeId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  themeId_lt?: Maybe<Scalars['Int']>
  themeId_lte?: Maybe<Scalars['Int']>
  themeId_not?: Maybe<Scalars['Int']>
  themeId_not_gt?: Maybe<Scalars['Int']>
  themeId_not_gte?: Maybe<Scalars['Int']>
  themeId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  themeId_not_lt?: Maybe<Scalars['Int']>
  themeId_not_lte?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  title_contains?: Maybe<Scalars['String']>
  title_ends_with?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  title_not_ends_with?: Maybe<Scalars['String']>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_starts_with?: Maybe<Scalars['String']>
  title_starts_with?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  urlTail?: Maybe<Scalars['String']>
  urlTail_contains?: Maybe<Scalars['String']>
  urlTail_ends_with?: Maybe<Scalars['String']>
  urlTail_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlTail_not?: Maybe<Scalars['String']>
  urlTail_not_contains?: Maybe<Scalars['String']>
  urlTail_not_ends_with?: Maybe<Scalars['String']>
  urlTail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlTail_not_starts_with?: Maybe<Scalars['String']>
  urlTail_starts_with?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
  votedDown?: Maybe<Scalars['Int']>
  votedDown_gt?: Maybe<Scalars['Int']>
  votedDown_gte?: Maybe<Scalars['Int']>
  votedDown_in?: Maybe<Array<Scalars['Int']>>
  votedDown_lt?: Maybe<Scalars['Int']>
  votedDown_lte?: Maybe<Scalars['Int']>
  votedDown_not?: Maybe<Scalars['Int']>
  votedDown_not_gt?: Maybe<Scalars['Int']>
  votedDown_not_gte?: Maybe<Scalars['Int']>
  votedDown_not_in?: Maybe<Array<Scalars['Int']>>
  votedDown_not_lt?: Maybe<Scalars['Int']>
  votedDown_not_lte?: Maybe<Scalars['Int']>
  votedUp?: Maybe<Scalars['Int']>
  votedUp_gt?: Maybe<Scalars['Int']>
  votedUp_gte?: Maybe<Scalars['Int']>
  votedUp_in?: Maybe<Array<Scalars['Int']>>
  votedUp_lt?: Maybe<Scalars['Int']>
  votedUp_lte?: Maybe<Scalars['Int']>
  votedUp_not?: Maybe<Scalars['Int']>
  votedUp_not_gt?: Maybe<Scalars['Int']>
  votedUp_not_gte?: Maybe<Scalars['Int']>
  votedUp_not_in?: Maybe<Array<Scalars['Int']>>
  votedUp_not_lt?: Maybe<Scalars['Int']>
  votedUp_not_lte?: Maybe<Scalars['Int']>
}

export type ArticleSort = {
  author?: Maybe<AuthorSort>
  authorId?: Maybe<SortOperationKind>
  brand?: Maybe<SortOperationKind>
  category?: Maybe<CategorySort>
  categoryId?: Maybe<SortOperationKind>
  contentHtml?: Maybe<SortOperationKind>
  createdDate?: Maybe<SortOperationKind>
  defaultImg?: Maybe<SortOperationKind>
  defaultImgAltText?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  disable?: Maybe<SortOperationKind>
  feature?: Maybe<SortOperationKind>
  iD?: Maybe<SortOperationKind>
  markdown?: Maybe<SortOperationKind>
  modifiedDate?: Maybe<SortOperationKind>
  preview?: Maybe<SortOperationKind>
  previewImg?: Maybe<SortOperationKind>
  previewImgAltText?: Maybe<SortOperationKind>
  priority?: Maybe<SortOperationKind>
  publishedDate?: Maybe<SortOperationKind>
  sEOTitle?: Maybe<SortOperationKind>
  theme?: Maybe<ThemeSort>
  themeId?: Maybe<SortOperationKind>
  title?: Maybe<SortOperationKind>
  url?: Maybe<SortOperationKind>
  urlTail?: Maybe<SortOperationKind>
  votedDown?: Maybe<SortOperationKind>
  votedUp?: Maybe<SortOperationKind>
}

export type Author = {
  __typename?: 'Author'
  articles?: Maybe<Array<Maybe<Article>>>
  authorImage?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  disable: Scalars['Boolean']
  firstName?: Maybe<Scalars['String']>
  fullBio?: Maybe<Scalars['String']>
  iD: Scalars['Int']
  joinedDate: Scalars['DateTime']
  lastName?: Maybe<Scalars['String']>
  modifiedDate: Scalars['DateTime']
  penName?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection'
  /** A list of edges. */
  edges?: Maybe<Array<AuthorEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Author>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Author>
}

export type AuthorFilter = {
  AND?: Maybe<Array<AuthorFilter>>
  articles_all?: Maybe<ArticleFilter>
  articles_any?: Maybe<Scalars['Boolean']>
  articles_none?: Maybe<ArticleFilter>
  articles_some?: Maybe<ArticleFilter>
  authorImage?: Maybe<Scalars['String']>
  authorImage_contains?: Maybe<Scalars['String']>
  authorImage_ends_with?: Maybe<Scalars['String']>
  authorImage_in?: Maybe<Array<Maybe<Scalars['String']>>>
  authorImage_not?: Maybe<Scalars['String']>
  authorImage_not_contains?: Maybe<Scalars['String']>
  authorImage_not_ends_with?: Maybe<Scalars['String']>
  authorImage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  authorImage_not_starts_with?: Maybe<Scalars['String']>
  authorImage_starts_with?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  bio_contains?: Maybe<Scalars['String']>
  bio_ends_with?: Maybe<Scalars['String']>
  bio_in?: Maybe<Array<Maybe<Scalars['String']>>>
  bio_not?: Maybe<Scalars['String']>
  bio_not_contains?: Maybe<Scalars['String']>
  bio_not_ends_with?: Maybe<Scalars['String']>
  bio_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  bio_not_starts_with?: Maybe<Scalars['String']>
  bio_starts_with?: Maybe<Scalars['String']>
  disable?: Maybe<Scalars['Boolean']>
  disable_not?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  firstName_contains?: Maybe<Scalars['String']>
  firstName_ends_with?: Maybe<Scalars['String']>
  firstName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  firstName_not?: Maybe<Scalars['String']>
  firstName_not_contains?: Maybe<Scalars['String']>
  firstName_not_ends_with?: Maybe<Scalars['String']>
  firstName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  firstName_not_starts_with?: Maybe<Scalars['String']>
  firstName_starts_with?: Maybe<Scalars['String']>
  fullBio?: Maybe<Scalars['String']>
  fullBio_contains?: Maybe<Scalars['String']>
  fullBio_ends_with?: Maybe<Scalars['String']>
  fullBio_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fullBio_not?: Maybe<Scalars['String']>
  fullBio_not_contains?: Maybe<Scalars['String']>
  fullBio_not_ends_with?: Maybe<Scalars['String']>
  fullBio_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fullBio_not_starts_with?: Maybe<Scalars['String']>
  fullBio_starts_with?: Maybe<Scalars['String']>
  iD?: Maybe<Scalars['Int']>
  iD_gt?: Maybe<Scalars['Int']>
  iD_gte?: Maybe<Scalars['Int']>
  iD_in?: Maybe<Array<Scalars['Int']>>
  iD_lt?: Maybe<Scalars['Int']>
  iD_lte?: Maybe<Scalars['Int']>
  iD_not?: Maybe<Scalars['Int']>
  iD_not_gt?: Maybe<Scalars['Int']>
  iD_not_gte?: Maybe<Scalars['Int']>
  iD_not_in?: Maybe<Array<Scalars['Int']>>
  iD_not_lt?: Maybe<Scalars['Int']>
  iD_not_lte?: Maybe<Scalars['Int']>
  joinedDate?: Maybe<Scalars['DateTime']>
  joinedDate_gt?: Maybe<Scalars['DateTime']>
  joinedDate_gte?: Maybe<Scalars['DateTime']>
  joinedDate_in?: Maybe<Array<Scalars['DateTime']>>
  joinedDate_lt?: Maybe<Scalars['DateTime']>
  joinedDate_lte?: Maybe<Scalars['DateTime']>
  joinedDate_not?: Maybe<Scalars['DateTime']>
  joinedDate_not_gt?: Maybe<Scalars['DateTime']>
  joinedDate_not_gte?: Maybe<Scalars['DateTime']>
  joinedDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  joinedDate_not_lt?: Maybe<Scalars['DateTime']>
  joinedDate_not_lte?: Maybe<Scalars['DateTime']>
  lastName?: Maybe<Scalars['String']>
  lastName_contains?: Maybe<Scalars['String']>
  lastName_ends_with?: Maybe<Scalars['String']>
  lastName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName_not?: Maybe<Scalars['String']>
  lastName_not_contains?: Maybe<Scalars['String']>
  lastName_not_ends_with?: Maybe<Scalars['String']>
  lastName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName_not_starts_with?: Maybe<Scalars['String']>
  lastName_starts_with?: Maybe<Scalars['String']>
  modifiedDate?: Maybe<Scalars['DateTime']>
  modifiedDate_gt?: Maybe<Scalars['DateTime']>
  modifiedDate_gte?: Maybe<Scalars['DateTime']>
  modifiedDate_in?: Maybe<Array<Scalars['DateTime']>>
  modifiedDate_lt?: Maybe<Scalars['DateTime']>
  modifiedDate_lte?: Maybe<Scalars['DateTime']>
  modifiedDate_not?: Maybe<Scalars['DateTime']>
  modifiedDate_not_gt?: Maybe<Scalars['DateTime']>
  modifiedDate_not_gte?: Maybe<Scalars['DateTime']>
  modifiedDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  modifiedDate_not_lt?: Maybe<Scalars['DateTime']>
  modifiedDate_not_lte?: Maybe<Scalars['DateTime']>
  OR?: Maybe<Array<AuthorFilter>>
  penName?: Maybe<Scalars['String']>
  penName_contains?: Maybe<Scalars['String']>
  penName_ends_with?: Maybe<Scalars['String']>
  penName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  penName_not?: Maybe<Scalars['String']>
  penName_not_contains?: Maybe<Scalars['String']>
  penName_not_ends_with?: Maybe<Scalars['String']>
  penName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  penName_not_starts_with?: Maybe<Scalars['String']>
  penName_starts_with?: Maybe<Scalars['String']>
}

export type AuthorSort = {
  authorImage?: Maybe<SortOperationKind>
  bio?: Maybe<SortOperationKind>
  disable?: Maybe<SortOperationKind>
  firstName?: Maybe<SortOperationKind>
  fullBio?: Maybe<SortOperationKind>
  iD?: Maybe<SortOperationKind>
  joinedDate?: Maybe<SortOperationKind>
  lastName?: Maybe<SortOperationKind>
  modifiedDate?: Maybe<SortOperationKind>
  penName?: Maybe<SortOperationKind>
}

export type BlogImage = {
  __typename?: 'BlogImage'
  altText?: Maybe<Scalars['String']>
  article?: Maybe<Article>
  articleId?: Maybe<Scalars['Int']>
  createdDate: Scalars['DateTime']
  fileName?: Maybe<Scalars['String']>
  iD: Scalars['Int']
  thumbnailUrl?: Maybe<Scalars['String']>
  updatedDate: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type BlogImageFilter = {
  altText?: Maybe<Scalars['String']>
  altText_contains?: Maybe<Scalars['String']>
  altText_ends_with?: Maybe<Scalars['String']>
  altText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  altText_not?: Maybe<Scalars['String']>
  altText_not_contains?: Maybe<Scalars['String']>
  altText_not_ends_with?: Maybe<Scalars['String']>
  altText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  altText_not_starts_with?: Maybe<Scalars['String']>
  altText_starts_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<BlogImageFilter>>
  article?: Maybe<ArticleFilter>
  articleId?: Maybe<Scalars['Int']>
  articleId_gt?: Maybe<Scalars['Int']>
  articleId_gte?: Maybe<Scalars['Int']>
  articleId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  articleId_lt?: Maybe<Scalars['Int']>
  articleId_lte?: Maybe<Scalars['Int']>
  articleId_not?: Maybe<Scalars['Int']>
  articleId_not_gt?: Maybe<Scalars['Int']>
  articleId_not_gte?: Maybe<Scalars['Int']>
  articleId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  articleId_not_lt?: Maybe<Scalars['Int']>
  articleId_not_lte?: Maybe<Scalars['Int']>
  createdDate?: Maybe<Scalars['DateTime']>
  createdDate_gt?: Maybe<Scalars['DateTime']>
  createdDate_gte?: Maybe<Scalars['DateTime']>
  createdDate_in?: Maybe<Array<Scalars['DateTime']>>
  createdDate_lt?: Maybe<Scalars['DateTime']>
  createdDate_lte?: Maybe<Scalars['DateTime']>
  createdDate_not?: Maybe<Scalars['DateTime']>
  createdDate_not_gt?: Maybe<Scalars['DateTime']>
  createdDate_not_gte?: Maybe<Scalars['DateTime']>
  createdDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdDate_not_lt?: Maybe<Scalars['DateTime']>
  createdDate_not_lte?: Maybe<Scalars['DateTime']>
  fileName?: Maybe<Scalars['String']>
  fileName_contains?: Maybe<Scalars['String']>
  fileName_ends_with?: Maybe<Scalars['String']>
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fileName_not?: Maybe<Scalars['String']>
  fileName_not_contains?: Maybe<Scalars['String']>
  fileName_not_ends_with?: Maybe<Scalars['String']>
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fileName_not_starts_with?: Maybe<Scalars['String']>
  fileName_starts_with?: Maybe<Scalars['String']>
  iD?: Maybe<Scalars['Int']>
  iD_gt?: Maybe<Scalars['Int']>
  iD_gte?: Maybe<Scalars['Int']>
  iD_in?: Maybe<Array<Scalars['Int']>>
  iD_lt?: Maybe<Scalars['Int']>
  iD_lte?: Maybe<Scalars['Int']>
  iD_not?: Maybe<Scalars['Int']>
  iD_not_gt?: Maybe<Scalars['Int']>
  iD_not_gte?: Maybe<Scalars['Int']>
  iD_not_in?: Maybe<Array<Scalars['Int']>>
  iD_not_lt?: Maybe<Scalars['Int']>
  iD_not_lte?: Maybe<Scalars['Int']>
  OR?: Maybe<Array<BlogImageFilter>>
  thumbnailUrl?: Maybe<Scalars['String']>
  thumbnailUrl_contains?: Maybe<Scalars['String']>
  thumbnailUrl_ends_with?: Maybe<Scalars['String']>
  thumbnailUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  thumbnailUrl_not?: Maybe<Scalars['String']>
  thumbnailUrl_not_contains?: Maybe<Scalars['String']>
  thumbnailUrl_not_ends_with?: Maybe<Scalars['String']>
  thumbnailUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  thumbnailUrl_not_starts_with?: Maybe<Scalars['String']>
  thumbnailUrl_starts_with?: Maybe<Scalars['String']>
  updatedDate?: Maybe<Scalars['DateTime']>
  updatedDate_gt?: Maybe<Scalars['DateTime']>
  updatedDate_gte?: Maybe<Scalars['DateTime']>
  updatedDate_in?: Maybe<Array<Scalars['DateTime']>>
  updatedDate_lt?: Maybe<Scalars['DateTime']>
  updatedDate_lte?: Maybe<Scalars['DateTime']>
  updatedDate_not?: Maybe<Scalars['DateTime']>
  updatedDate_not_gt?: Maybe<Scalars['DateTime']>
  updatedDate_not_gte?: Maybe<Scalars['DateTime']>
  updatedDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedDate_not_lt?: Maybe<Scalars['DateTime']>
  updatedDate_not_lte?: Maybe<Scalars['DateTime']>
  url?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
}

export enum Brands {
  Speedycash = 'SPEEDYCASH',
  Rapidcash = 'RAPIDCASH',
  Cashmoney = 'CASHMONEY',
  Lenddirect = 'LENDDIRECT',
  Optplusus = 'OPTPLUSUS',
  Optplusca = 'OPTPLUSCA',
  Moneybox = 'MONEYBOX',
  Aviocredit = 'AVIOCREDIT',
  Curo = 'CURO',
  Vergecredit = 'VERGECREDIT',
  Revolvefinance = 'REVOLVEFINANCE',
}

export type Category = {
  __typename?: 'Category'
  articles?: Maybe<Array<Maybe<Article>>>
  brand: Brands
  defaultTheme?: Maybe<Theme>
  defaultThemeId?: Maybe<Scalars['Int']>
  disable: Scalars['Boolean']
  icon?: Maybe<Scalars['String']>
  iD: Scalars['Int']
  image?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  priority: Scalars['Int']
  seoDesc?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<CategoryEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Category>
}

export type CategoryFilter = {
  AND?: Maybe<Array<CategoryFilter>>
  articles_all?: Maybe<ArticleFilter>
  articles_any?: Maybe<Scalars['Boolean']>
  articles_none?: Maybe<ArticleFilter>
  articles_some?: Maybe<ArticleFilter>
  brand?: Maybe<Brands>
  brand_gt?: Maybe<Brands>
  brand_gte?: Maybe<Brands>
  brand_in?: Maybe<Array<Brands>>
  brand_lt?: Maybe<Brands>
  brand_lte?: Maybe<Brands>
  brand_not?: Maybe<Brands>
  brand_not_gt?: Maybe<Brands>
  brand_not_gte?: Maybe<Brands>
  brand_not_in?: Maybe<Array<Brands>>
  brand_not_lt?: Maybe<Brands>
  brand_not_lte?: Maybe<Brands>
  defaultTheme?: Maybe<ThemeFilter>
  defaultThemeId?: Maybe<Scalars['Int']>
  defaultThemeId_gt?: Maybe<Scalars['Int']>
  defaultThemeId_gte?: Maybe<Scalars['Int']>
  defaultThemeId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  defaultThemeId_lt?: Maybe<Scalars['Int']>
  defaultThemeId_lte?: Maybe<Scalars['Int']>
  defaultThemeId_not?: Maybe<Scalars['Int']>
  defaultThemeId_not_gt?: Maybe<Scalars['Int']>
  defaultThemeId_not_gte?: Maybe<Scalars['Int']>
  defaultThemeId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  defaultThemeId_not_lt?: Maybe<Scalars['Int']>
  defaultThemeId_not_lte?: Maybe<Scalars['Int']>
  disable?: Maybe<Scalars['Boolean']>
  disable_not?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  icon_contains?: Maybe<Scalars['String']>
  icon_ends_with?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not?: Maybe<Scalars['String']>
  icon_not_contains?: Maybe<Scalars['String']>
  icon_not_ends_with?: Maybe<Scalars['String']>
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not_starts_with?: Maybe<Scalars['String']>
  icon_starts_with?: Maybe<Scalars['String']>
  iD?: Maybe<Scalars['Int']>
  iD_gt?: Maybe<Scalars['Int']>
  iD_gte?: Maybe<Scalars['Int']>
  iD_in?: Maybe<Array<Scalars['Int']>>
  iD_lt?: Maybe<Scalars['Int']>
  iD_lte?: Maybe<Scalars['Int']>
  iD_not?: Maybe<Scalars['Int']>
  iD_not_gt?: Maybe<Scalars['Int']>
  iD_not_gte?: Maybe<Scalars['Int']>
  iD_not_in?: Maybe<Array<Scalars['Int']>>
  iD_not_lt?: Maybe<Scalars['Int']>
  iD_not_lte?: Maybe<Scalars['Int']>
  image?: Maybe<Scalars['String']>
  image_contains?: Maybe<Scalars['String']>
  image_ends_with?: Maybe<Scalars['String']>
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>
  image_not?: Maybe<Scalars['String']>
  image_not_contains?: Maybe<Scalars['String']>
  image_not_ends_with?: Maybe<Scalars['String']>
  image_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  image_not_starts_with?: Maybe<Scalars['String']>
  image_starts_with?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  info_contains?: Maybe<Scalars['String']>
  info_ends_with?: Maybe<Scalars['String']>
  info_in?: Maybe<Array<Maybe<Scalars['String']>>>
  info_not?: Maybe<Scalars['String']>
  info_not_contains?: Maybe<Scalars['String']>
  info_not_ends_with?: Maybe<Scalars['String']>
  info_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  info_not_starts_with?: Maybe<Scalars['String']>
  info_starts_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<CategoryFilter>>
  priority?: Maybe<Scalars['Int']>
  priority_gt?: Maybe<Scalars['Int']>
  priority_gte?: Maybe<Scalars['Int']>
  priority_in?: Maybe<Array<Scalars['Int']>>
  priority_lt?: Maybe<Scalars['Int']>
  priority_lte?: Maybe<Scalars['Int']>
  priority_not?: Maybe<Scalars['Int']>
  priority_not_gt?: Maybe<Scalars['Int']>
  priority_not_gte?: Maybe<Scalars['Int']>
  priority_not_in?: Maybe<Array<Scalars['Int']>>
  priority_not_lt?: Maybe<Scalars['Int']>
  priority_not_lte?: Maybe<Scalars['Int']>
  seoDesc?: Maybe<Scalars['String']>
  seoDesc_contains?: Maybe<Scalars['String']>
  seoDesc_ends_with?: Maybe<Scalars['String']>
  seoDesc_in?: Maybe<Array<Maybe<Scalars['String']>>>
  seoDesc_not?: Maybe<Scalars['String']>
  seoDesc_not_contains?: Maybe<Scalars['String']>
  seoDesc_not_ends_with?: Maybe<Scalars['String']>
  seoDesc_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  seoDesc_not_starts_with?: Maybe<Scalars['String']>
  seoDesc_starts_with?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  seoTitle_contains?: Maybe<Scalars['String']>
  seoTitle_ends_with?: Maybe<Scalars['String']>
  seoTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  seoTitle_not?: Maybe<Scalars['String']>
  seoTitle_not_contains?: Maybe<Scalars['String']>
  seoTitle_not_ends_with?: Maybe<Scalars['String']>
  seoTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  seoTitle_not_starts_with?: Maybe<Scalars['String']>
  seoTitle_starts_with?: Maybe<Scalars['String']>
}

export type CategorySort = {
  brand?: Maybe<SortOperationKind>
  defaultTheme?: Maybe<ThemeSort>
  defaultThemeId?: Maybe<SortOperationKind>
  disable?: Maybe<SortOperationKind>
  icon?: Maybe<SortOperationKind>
  iD?: Maybe<SortOperationKind>
  image?: Maybe<SortOperationKind>
  info?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  priority?: Maybe<SortOperationKind>
  seoDesc?: Maybe<SortOperationKind>
  seoTitle?: Maybe<SortOperationKind>
}

export type City = {
  __typename?: 'City'
  body?: Maybe<Scalars['String']>
  brand: Brands
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  disabledAt?: Maybe<Scalars['DateTime']>
  flashAlert?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  id: Scalars['Int']
  imageUrl?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  state?: Maybe<State>
  stateId: Scalars['Int']
  stores?: Maybe<Array<Maybe<Store>>>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  urlSafeName?: Maybe<Scalars['String']>
}

export type CityFilter = {
  AND?: Maybe<Array<CityFilter>>
  body?: Maybe<Scalars['String']>
  body_contains?: Maybe<Scalars['String']>
  body_ends_with?: Maybe<Scalars['String']>
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>
  body_not?: Maybe<Scalars['String']>
  body_not_contains?: Maybe<Scalars['String']>
  body_not_ends_with?: Maybe<Scalars['String']>
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  body_not_starts_with?: Maybe<Scalars['String']>
  body_starts_with?: Maybe<Scalars['String']>
  brand?: Maybe<Brands>
  brand_gt?: Maybe<Brands>
  brand_gte?: Maybe<Brands>
  brand_in?: Maybe<Array<Brands>>
  brand_lt?: Maybe<Brands>
  brand_lte?: Maybe<Brands>
  brand_not?: Maybe<Brands>
  brand_not_gt?: Maybe<Brands>
  brand_not_gte?: Maybe<Brands>
  brand_not_in?: Maybe<Array<Brands>>
  brand_not_lt?: Maybe<Brands>
  brand_not_lte?: Maybe<Brands>
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  chatMeterWidgetCode_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not_starts_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_starts_with?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_not_gt?: Maybe<Scalars['DateTime']>
  createdAt_not_gte?: Maybe<Scalars['DateTime']>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_lt?: Maybe<Scalars['DateTime']>
  createdAt_not_lte?: Maybe<Scalars['DateTime']>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  flashAlert?: Maybe<Scalars['String']>
  flashAlert_contains?: Maybe<Scalars['String']>
  flashAlert_ends_with?: Maybe<Scalars['String']>
  flashAlert_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not?: Maybe<Scalars['String']>
  flashAlert_not_contains?: Maybe<Scalars['String']>
  flashAlert_not_ends_with?: Maybe<Scalars['String']>
  flashAlert_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not_starts_with?: Maybe<Scalars['String']>
  flashAlert_starts_with?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  h1_contains?: Maybe<Scalars['String']>
  h1_ends_with?: Maybe<Scalars['String']>
  h1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not?: Maybe<Scalars['String']>
  h1_not_contains?: Maybe<Scalars['String']>
  h1_not_ends_with?: Maybe<Scalars['String']>
  h1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not_starts_with?: Maybe<Scalars['String']>
  h1_starts_with?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  imageUrl?: Maybe<Scalars['String']>
  imageUrl_contains?: Maybe<Scalars['String']>
  imageUrl_ends_with?: Maybe<Scalars['String']>
  imageUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imageUrl_not?: Maybe<Scalars['String']>
  imageUrl_not_contains?: Maybe<Scalars['String']>
  imageUrl_not_ends_with?: Maybe<Scalars['String']>
  imageUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imageUrl_not_starts_with?: Maybe<Scalars['String']>
  imageUrl_starts_with?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaDescription_contains?: Maybe<Scalars['String']>
  metaDescription_ends_with?: Maybe<Scalars['String']>
  metaDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not?: Maybe<Scalars['String']>
  metaDescription_not_contains?: Maybe<Scalars['String']>
  metaDescription_not_ends_with?: Maybe<Scalars['String']>
  metaDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not_starts_with?: Maybe<Scalars['String']>
  metaDescription_starts_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<CityFilter>>
  state?: Maybe<StateFilter>
  stateId?: Maybe<Scalars['Int']>
  stateId_gt?: Maybe<Scalars['Int']>
  stateId_gte?: Maybe<Scalars['Int']>
  stateId_in?: Maybe<Array<Scalars['Int']>>
  stateId_lt?: Maybe<Scalars['Int']>
  stateId_lte?: Maybe<Scalars['Int']>
  stateId_not?: Maybe<Scalars['Int']>
  stateId_not_gt?: Maybe<Scalars['Int']>
  stateId_not_gte?: Maybe<Scalars['Int']>
  stateId_not_in?: Maybe<Array<Scalars['Int']>>
  stateId_not_lt?: Maybe<Scalars['Int']>
  stateId_not_lte?: Maybe<Scalars['Int']>
  stores_all?: Maybe<StoreFilter>
  stores_any?: Maybe<Scalars['Boolean']>
  stores_none?: Maybe<StoreFilter>
  stores_some?: Maybe<StoreFilter>
  title?: Maybe<Scalars['String']>
  title_contains?: Maybe<Scalars['String']>
  title_ends_with?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  title_not_ends_with?: Maybe<Scalars['String']>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_starts_with?: Maybe<Scalars['String']>
  title_starts_with?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
  urlSafeName?: Maybe<Scalars['String']>
  urlSafeName_contains?: Maybe<Scalars['String']>
  urlSafeName_ends_with?: Maybe<Scalars['String']>
  urlSafeName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlSafeName_not?: Maybe<Scalars['String']>
  urlSafeName_not_contains?: Maybe<Scalars['String']>
  urlSafeName_not_ends_with?: Maybe<Scalars['String']>
  urlSafeName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlSafeName_not_starts_with?: Maybe<Scalars['String']>
  urlSafeName_starts_with?: Maybe<Scalars['String']>
}

export type CitySort = {
  body?: Maybe<SortOperationKind>
  brand?: Maybe<SortOperationKind>
  chatMeterWidgetCode?: Maybe<SortOperationKind>
  createdAt?: Maybe<SortOperationKind>
  disabledAt?: Maybe<SortOperationKind>
  flashAlert?: Maybe<SortOperationKind>
  h1?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
  imageUrl?: Maybe<SortOperationKind>
  metaDescription?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  state?: Maybe<StateSort>
  stateId?: Maybe<SortOperationKind>
  title?: Maybe<SortOperationKind>
  updatedAt?: Maybe<SortOperationKind>
  urlSafeName?: Maybe<SortOperationKind>
}

export enum Country {
  Us = 'US',
  Can = 'CAN',
  Uk = 'UK',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type Product = {
  __typename?: 'Product'
  cashMoneyUrl?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  disabledAt?: Maybe<Scalars['DateTime']>
  icon?: Maybe<Scalars['String']>
  id: Scalars['Int']
  label?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  orderIndex?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  speedyCashUrl?: Maybe<Scalars['String']>
  states?: Maybe<Array<Maybe<StateProductPivot>>>
  stores?: Maybe<Array<Maybe<StoreProductPivot>>>
  updatedAt: Scalars['DateTime']
}

export type ProductFilter = {
  AND?: Maybe<Array<ProductFilter>>
  cashMoneyUrl?: Maybe<Scalars['String']>
  cashMoneyUrl_contains?: Maybe<Scalars['String']>
  cashMoneyUrl_ends_with?: Maybe<Scalars['String']>
  cashMoneyUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  cashMoneyUrl_not?: Maybe<Scalars['String']>
  cashMoneyUrl_not_contains?: Maybe<Scalars['String']>
  cashMoneyUrl_not_ends_with?: Maybe<Scalars['String']>
  cashMoneyUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  cashMoneyUrl_not_starts_with?: Maybe<Scalars['String']>
  cashMoneyUrl_starts_with?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_not_gt?: Maybe<Scalars['DateTime']>
  createdAt_not_gte?: Maybe<Scalars['DateTime']>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_lt?: Maybe<Scalars['DateTime']>
  createdAt_not_lte?: Maybe<Scalars['DateTime']>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  icon?: Maybe<Scalars['String']>
  icon_contains?: Maybe<Scalars['String']>
  icon_ends_with?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not?: Maybe<Scalars['String']>
  icon_not_contains?: Maybe<Scalars['String']>
  icon_not_ends_with?: Maybe<Scalars['String']>
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not_starts_with?: Maybe<Scalars['String']>
  icon_starts_with?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  label_contains?: Maybe<Scalars['String']>
  label_ends_with?: Maybe<Scalars['String']>
  label_in?: Maybe<Array<Maybe<Scalars['String']>>>
  label_not?: Maybe<Scalars['String']>
  label_not_contains?: Maybe<Scalars['String']>
  label_not_ends_with?: Maybe<Scalars['String']>
  label_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  label_not_starts_with?: Maybe<Scalars['String']>
  label_starts_with?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  notes_contains?: Maybe<Scalars['String']>
  notes_ends_with?: Maybe<Scalars['String']>
  notes_in?: Maybe<Array<Maybe<Scalars['String']>>>
  notes_not?: Maybe<Scalars['String']>
  notes_not_contains?: Maybe<Scalars['String']>
  notes_not_ends_with?: Maybe<Scalars['String']>
  notes_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  notes_not_starts_with?: Maybe<Scalars['String']>
  notes_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<ProductFilter>>
  orderIndex?: Maybe<Scalars['Int']>
  orderIndex_gt?: Maybe<Scalars['Int']>
  orderIndex_gte?: Maybe<Scalars['Int']>
  orderIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_lt?: Maybe<Scalars['Int']>
  orderIndex_lte?: Maybe<Scalars['Int']>
  orderIndex_not?: Maybe<Scalars['Int']>
  orderIndex_not_gt?: Maybe<Scalars['Int']>
  orderIndex_not_gte?: Maybe<Scalars['Int']>
  orderIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_not_lt?: Maybe<Scalars['Int']>
  orderIndex_not_lte?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  productName_contains?: Maybe<Scalars['String']>
  productName_ends_with?: Maybe<Scalars['String']>
  productName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productName_not?: Maybe<Scalars['String']>
  productName_not_contains?: Maybe<Scalars['String']>
  productName_not_ends_with?: Maybe<Scalars['String']>
  productName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productName_not_starts_with?: Maybe<Scalars['String']>
  productName_starts_with?: Maybe<Scalars['String']>
  speedyCashUrl?: Maybe<Scalars['String']>
  speedyCashUrl_contains?: Maybe<Scalars['String']>
  speedyCashUrl_ends_with?: Maybe<Scalars['String']>
  speedyCashUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  speedyCashUrl_not?: Maybe<Scalars['String']>
  speedyCashUrl_not_contains?: Maybe<Scalars['String']>
  speedyCashUrl_not_ends_with?: Maybe<Scalars['String']>
  speedyCashUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  speedyCashUrl_not_starts_with?: Maybe<Scalars['String']>
  speedyCashUrl_starts_with?: Maybe<Scalars['String']>
  states_all?: Maybe<StateProductPivotFilter>
  states_any?: Maybe<Scalars['Boolean']>
  states_none?: Maybe<StateProductPivotFilter>
  states_some?: Maybe<StateProductPivotFilter>
  stores_all?: Maybe<StoreProductPivotFilter>
  stores_any?: Maybe<Scalars['Boolean']>
  stores_none?: Maybe<StoreProductPivotFilter>
  stores_some?: Maybe<StoreProductPivotFilter>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
}

export type ProductSort = {
  cashMoneyUrl?: Maybe<SortOperationKind>
  createdAt?: Maybe<SortOperationKind>
  disabledAt?: Maybe<SortOperationKind>
  icon?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
  label?: Maybe<SortOperationKind>
  notes?: Maybe<SortOperationKind>
  orderIndex?: Maybe<SortOperationKind>
  productName?: Maybe<SortOperationKind>
  speedyCashUrl?: Maybe<SortOperationKind>
  updatedAt?: Maybe<SortOperationKind>
}

export type Query = {
  __typename?: 'Query'
  article?: Maybe<Article>
  articles?: Maybe<ArticleConnection>
  author?: Maybe<Author>
  authors?: Maybe<AuthorConnection>
  categories?: Maybe<CategoryConnection>
  category?: Maybe<Category>
  stateProducts?: Maybe<StateProductPivotConnection>
  store?: Maybe<Store>
  stores?: Maybe<StoreConnection>
  webpage?: Maybe<WebpageContent>
}

export type QueryArticleArgs = {
  articleId: Scalars['Int']
}

export type QueryArticlesArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['PaginationAmount']>
  last?: Maybe<Scalars['PaginationAmount']>
  order_by?: Maybe<ArticleSort>
  where?: Maybe<ArticleFilter>
}

export type QueryAuthorArgs = {
  penName?: Maybe<Scalars['String']>
}

export type QueryAuthorsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['PaginationAmount']>
  last?: Maybe<Scalars['PaginationAmount']>
  order_by?: Maybe<AuthorSort>
  where?: Maybe<AuthorFilter>
}

export type QueryCategoriesArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['PaginationAmount']>
  last?: Maybe<Scalars['PaginationAmount']>
  order_by?: Maybe<CategorySort>
  where?: Maybe<CategoryFilter>
}

export type QueryCategoryArgs = {
  categoryName?: Maybe<Scalars['String']>
}

export type QueryStateProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['PaginationAmount']>
  last?: Maybe<Scalars['PaginationAmount']>
  order_by?: Maybe<StateProductPivotSort>
  where?: Maybe<StateProductPivotFilter>
}

export type QueryStoreArgs = {
  storeId: Scalars['Int']
}

export type QueryStoresArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['PaginationAmount']>
  last?: Maybe<Scalars['PaginationAmount']>
  order_by?: Maybe<StoreSort>
  where?: Maybe<StoreFilter>
}

export type QueryWebpageArgs = {
  brand: Brands
  path?: Maybe<Scalars['String']>
}

export enum SortOperationKind {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type State = {
  __typename?: 'State'
  abbreviation?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  brand: Brands
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  cities?: Maybe<Array<Maybe<City>>>
  country: Country
  createdAt: Scalars['DateTime']
  disabledAt?: Maybe<Scalars['DateTime']>
  flashAlert?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  id: Scalars['Int']
  imageUrl?: Maybe<Scalars['String']>
  loanInStore: Scalars['Boolean']
  loanOnline: Scalars['Boolean']
  metaDescription?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  products?: Maybe<Array<Maybe<StateProductPivot>>>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  urlSafeName?: Maybe<Scalars['String']>
}

export type StateFilter = {
  abbreviation?: Maybe<Scalars['String']>
  abbreviation_contains?: Maybe<Scalars['String']>
  abbreviation_ends_with?: Maybe<Scalars['String']>
  abbreviation_in?: Maybe<Array<Maybe<Scalars['String']>>>
  abbreviation_not?: Maybe<Scalars['String']>
  abbreviation_not_contains?: Maybe<Scalars['String']>
  abbreviation_not_ends_with?: Maybe<Scalars['String']>
  abbreviation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  abbreviation_not_starts_with?: Maybe<Scalars['String']>
  abbreviation_starts_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<StateFilter>>
  body?: Maybe<Scalars['String']>
  body_contains?: Maybe<Scalars['String']>
  body_ends_with?: Maybe<Scalars['String']>
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>
  body_not?: Maybe<Scalars['String']>
  body_not_contains?: Maybe<Scalars['String']>
  body_not_ends_with?: Maybe<Scalars['String']>
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  body_not_starts_with?: Maybe<Scalars['String']>
  body_starts_with?: Maybe<Scalars['String']>
  brand?: Maybe<Brands>
  brand_gt?: Maybe<Brands>
  brand_gte?: Maybe<Brands>
  brand_in?: Maybe<Array<Brands>>
  brand_lt?: Maybe<Brands>
  brand_lte?: Maybe<Brands>
  brand_not?: Maybe<Brands>
  brand_not_gt?: Maybe<Brands>
  brand_not_gte?: Maybe<Brands>
  brand_not_in?: Maybe<Array<Brands>>
  brand_not_lt?: Maybe<Brands>
  brand_not_lte?: Maybe<Brands>
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  chatMeterWidgetCode_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not_starts_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_starts_with?: Maybe<Scalars['String']>
  cities_all?: Maybe<CityFilter>
  cities_any?: Maybe<Scalars['Boolean']>
  cities_none?: Maybe<CityFilter>
  cities_some?: Maybe<CityFilter>
  country?: Maybe<Country>
  country_gt?: Maybe<Country>
  country_gte?: Maybe<Country>
  country_in?: Maybe<Array<Country>>
  country_lt?: Maybe<Country>
  country_lte?: Maybe<Country>
  country_not?: Maybe<Country>
  country_not_gt?: Maybe<Country>
  country_not_gte?: Maybe<Country>
  country_not_in?: Maybe<Array<Country>>
  country_not_lt?: Maybe<Country>
  country_not_lte?: Maybe<Country>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_not_gt?: Maybe<Scalars['DateTime']>
  createdAt_not_gte?: Maybe<Scalars['DateTime']>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_lt?: Maybe<Scalars['DateTime']>
  createdAt_not_lte?: Maybe<Scalars['DateTime']>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  flashAlert?: Maybe<Scalars['String']>
  flashAlert_contains?: Maybe<Scalars['String']>
  flashAlert_ends_with?: Maybe<Scalars['String']>
  flashAlert_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not?: Maybe<Scalars['String']>
  flashAlert_not_contains?: Maybe<Scalars['String']>
  flashAlert_not_ends_with?: Maybe<Scalars['String']>
  flashAlert_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not_starts_with?: Maybe<Scalars['String']>
  flashAlert_starts_with?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  h1_contains?: Maybe<Scalars['String']>
  h1_ends_with?: Maybe<Scalars['String']>
  h1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not?: Maybe<Scalars['String']>
  h1_not_contains?: Maybe<Scalars['String']>
  h1_not_ends_with?: Maybe<Scalars['String']>
  h1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not_starts_with?: Maybe<Scalars['String']>
  h1_starts_with?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  imageUrl?: Maybe<Scalars['String']>
  imageUrl_contains?: Maybe<Scalars['String']>
  imageUrl_ends_with?: Maybe<Scalars['String']>
  imageUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imageUrl_not?: Maybe<Scalars['String']>
  imageUrl_not_contains?: Maybe<Scalars['String']>
  imageUrl_not_ends_with?: Maybe<Scalars['String']>
  imageUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imageUrl_not_starts_with?: Maybe<Scalars['String']>
  imageUrl_starts_with?: Maybe<Scalars['String']>
  loanInStore?: Maybe<Scalars['Boolean']>
  loanInStore_not?: Maybe<Scalars['Boolean']>
  loanOnline?: Maybe<Scalars['Boolean']>
  loanOnline_not?: Maybe<Scalars['Boolean']>
  metaDescription?: Maybe<Scalars['String']>
  metaDescription_contains?: Maybe<Scalars['String']>
  metaDescription_ends_with?: Maybe<Scalars['String']>
  metaDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not?: Maybe<Scalars['String']>
  metaDescription_not_contains?: Maybe<Scalars['String']>
  metaDescription_not_ends_with?: Maybe<Scalars['String']>
  metaDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not_starts_with?: Maybe<Scalars['String']>
  metaDescription_starts_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<StateFilter>>
  products_all?: Maybe<StateProductPivotFilter>
  products_any?: Maybe<Scalars['Boolean']>
  products_none?: Maybe<StateProductPivotFilter>
  products_some?: Maybe<StateProductPivotFilter>
  title?: Maybe<Scalars['String']>
  title_contains?: Maybe<Scalars['String']>
  title_ends_with?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  title_not_ends_with?: Maybe<Scalars['String']>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_starts_with?: Maybe<Scalars['String']>
  title_starts_with?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
  urlSafeName?: Maybe<Scalars['String']>
  urlSafeName_contains?: Maybe<Scalars['String']>
  urlSafeName_ends_with?: Maybe<Scalars['String']>
  urlSafeName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlSafeName_not?: Maybe<Scalars['String']>
  urlSafeName_not_contains?: Maybe<Scalars['String']>
  urlSafeName_not_ends_with?: Maybe<Scalars['String']>
  urlSafeName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  urlSafeName_not_starts_with?: Maybe<Scalars['String']>
  urlSafeName_starts_with?: Maybe<Scalars['String']>
}

export type StateProductPivot = {
  __typename?: 'StateProductPivot'
  disabledAt?: Maybe<Scalars['DateTime']>
  id: Scalars['Int']
  isStore: Scalars['Boolean']
  isWeb: Scalars['Boolean']
  orderIndex?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  productId: Scalars['Int']
  productMaxAmount?: Maybe<Scalars['Int']>
  productMinAmount?: Maybe<Scalars['Int']>
  productText?: Maybe<Scalars['String']>
  state?: Maybe<State>
  stateId: Scalars['Int']
  storeMaxAmount?: Maybe<Scalars['Int']>
  storeMinAmount?: Maybe<Scalars['Int']>
  storeProducts?: Maybe<Array<Maybe<StoreProductPivot>>>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
  webMaxAmount?: Maybe<Scalars['Int']>
  webMinAmount?: Maybe<Scalars['Int']>
}

/** A connection to a list of items. */
export type StateProductPivotConnection = {
  __typename?: 'StateProductPivotConnection'
  /** A list of edges. */
  edges?: Maybe<Array<StateProductPivotEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<StateProductPivot>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type StateProductPivotEdge = {
  __typename?: 'StateProductPivotEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<StateProductPivot>
}

export type StateProductPivotFilter = {
  AND?: Maybe<Array<StateProductPivotFilter>>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  isStore?: Maybe<Scalars['Boolean']>
  isStore_not?: Maybe<Scalars['Boolean']>
  isWeb?: Maybe<Scalars['Boolean']>
  isWeb_not?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<StateProductPivotFilter>>
  orderIndex?: Maybe<Scalars['Int']>
  orderIndex_gt?: Maybe<Scalars['Int']>
  orderIndex_gte?: Maybe<Scalars['Int']>
  orderIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_lt?: Maybe<Scalars['Int']>
  orderIndex_lte?: Maybe<Scalars['Int']>
  orderIndex_not?: Maybe<Scalars['Int']>
  orderIndex_not_gt?: Maybe<Scalars['Int']>
  orderIndex_not_gte?: Maybe<Scalars['Int']>
  orderIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_not_lt?: Maybe<Scalars['Int']>
  orderIndex_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  productId?: Maybe<Scalars['Int']>
  productId_gt?: Maybe<Scalars['Int']>
  productId_gte?: Maybe<Scalars['Int']>
  productId_in?: Maybe<Array<Scalars['Int']>>
  productId_lt?: Maybe<Scalars['Int']>
  productId_lte?: Maybe<Scalars['Int']>
  productId_not?: Maybe<Scalars['Int']>
  productId_not_gt?: Maybe<Scalars['Int']>
  productId_not_gte?: Maybe<Scalars['Int']>
  productId_not_in?: Maybe<Array<Scalars['Int']>>
  productId_not_lt?: Maybe<Scalars['Int']>
  productId_not_lte?: Maybe<Scalars['Int']>
  productMaxAmount?: Maybe<Scalars['Int']>
  productMaxAmount_gt?: Maybe<Scalars['Int']>
  productMaxAmount_gte?: Maybe<Scalars['Int']>
  productMaxAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  productMaxAmount_lt?: Maybe<Scalars['Int']>
  productMaxAmount_lte?: Maybe<Scalars['Int']>
  productMaxAmount_not?: Maybe<Scalars['Int']>
  productMaxAmount_not_gt?: Maybe<Scalars['Int']>
  productMaxAmount_not_gte?: Maybe<Scalars['Int']>
  productMaxAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  productMaxAmount_not_lt?: Maybe<Scalars['Int']>
  productMaxAmount_not_lte?: Maybe<Scalars['Int']>
  productMinAmount?: Maybe<Scalars['Int']>
  productMinAmount_gt?: Maybe<Scalars['Int']>
  productMinAmount_gte?: Maybe<Scalars['Int']>
  productMinAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  productMinAmount_lt?: Maybe<Scalars['Int']>
  productMinAmount_lte?: Maybe<Scalars['Int']>
  productMinAmount_not?: Maybe<Scalars['Int']>
  productMinAmount_not_gt?: Maybe<Scalars['Int']>
  productMinAmount_not_gte?: Maybe<Scalars['Int']>
  productMinAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  productMinAmount_not_lt?: Maybe<Scalars['Int']>
  productMinAmount_not_lte?: Maybe<Scalars['Int']>
  productText?: Maybe<Scalars['String']>
  productText_contains?: Maybe<Scalars['String']>
  productText_ends_with?: Maybe<Scalars['String']>
  productText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productText_not?: Maybe<Scalars['String']>
  productText_not_contains?: Maybe<Scalars['String']>
  productText_not_ends_with?: Maybe<Scalars['String']>
  productText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productText_not_starts_with?: Maybe<Scalars['String']>
  productText_starts_with?: Maybe<Scalars['String']>
  state?: Maybe<StateFilter>
  stateId?: Maybe<Scalars['Int']>
  stateId_gt?: Maybe<Scalars['Int']>
  stateId_gte?: Maybe<Scalars['Int']>
  stateId_in?: Maybe<Array<Scalars['Int']>>
  stateId_lt?: Maybe<Scalars['Int']>
  stateId_lte?: Maybe<Scalars['Int']>
  stateId_not?: Maybe<Scalars['Int']>
  stateId_not_gt?: Maybe<Scalars['Int']>
  stateId_not_gte?: Maybe<Scalars['Int']>
  stateId_not_in?: Maybe<Array<Scalars['Int']>>
  stateId_not_lt?: Maybe<Scalars['Int']>
  stateId_not_lte?: Maybe<Scalars['Int']>
  storeMaxAmount?: Maybe<Scalars['Int']>
  storeMaxAmount_gt?: Maybe<Scalars['Int']>
  storeMaxAmount_gte?: Maybe<Scalars['Int']>
  storeMaxAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeMaxAmount_lt?: Maybe<Scalars['Int']>
  storeMaxAmount_lte?: Maybe<Scalars['Int']>
  storeMaxAmount_not?: Maybe<Scalars['Int']>
  storeMaxAmount_not_gt?: Maybe<Scalars['Int']>
  storeMaxAmount_not_gte?: Maybe<Scalars['Int']>
  storeMaxAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeMaxAmount_not_lt?: Maybe<Scalars['Int']>
  storeMaxAmount_not_lte?: Maybe<Scalars['Int']>
  storeMinAmount?: Maybe<Scalars['Int']>
  storeMinAmount_gt?: Maybe<Scalars['Int']>
  storeMinAmount_gte?: Maybe<Scalars['Int']>
  storeMinAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeMinAmount_lt?: Maybe<Scalars['Int']>
  storeMinAmount_lte?: Maybe<Scalars['Int']>
  storeMinAmount_not?: Maybe<Scalars['Int']>
  storeMinAmount_not_gt?: Maybe<Scalars['Int']>
  storeMinAmount_not_gte?: Maybe<Scalars['Int']>
  storeMinAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeMinAmount_not_lt?: Maybe<Scalars['Int']>
  storeMinAmount_not_lte?: Maybe<Scalars['Int']>
  storeProducts_all?: Maybe<StoreProductPivotFilter>
  storeProducts_any?: Maybe<Scalars['Boolean']>
  storeProducts_none?: Maybe<StoreProductPivotFilter>
  storeProducts_some?: Maybe<StoreProductPivotFilter>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
  url?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
  webMaxAmount?: Maybe<Scalars['Int']>
  webMaxAmount_gt?: Maybe<Scalars['Int']>
  webMaxAmount_gte?: Maybe<Scalars['Int']>
  webMaxAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  webMaxAmount_lt?: Maybe<Scalars['Int']>
  webMaxAmount_lte?: Maybe<Scalars['Int']>
  webMaxAmount_not?: Maybe<Scalars['Int']>
  webMaxAmount_not_gt?: Maybe<Scalars['Int']>
  webMaxAmount_not_gte?: Maybe<Scalars['Int']>
  webMaxAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  webMaxAmount_not_lt?: Maybe<Scalars['Int']>
  webMaxAmount_not_lte?: Maybe<Scalars['Int']>
  webMinAmount?: Maybe<Scalars['Int']>
  webMinAmount_gt?: Maybe<Scalars['Int']>
  webMinAmount_gte?: Maybe<Scalars['Int']>
  webMinAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  webMinAmount_lt?: Maybe<Scalars['Int']>
  webMinAmount_lte?: Maybe<Scalars['Int']>
  webMinAmount_not?: Maybe<Scalars['Int']>
  webMinAmount_not_gt?: Maybe<Scalars['Int']>
  webMinAmount_not_gte?: Maybe<Scalars['Int']>
  webMinAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  webMinAmount_not_lt?: Maybe<Scalars['Int']>
  webMinAmount_not_lte?: Maybe<Scalars['Int']>
}

export type StateProductPivotSort = {
  disabledAt?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
  isStore?: Maybe<SortOperationKind>
  isWeb?: Maybe<SortOperationKind>
  orderIndex?: Maybe<SortOperationKind>
  product?: Maybe<ProductSort>
  productId?: Maybe<SortOperationKind>
  productMaxAmount?: Maybe<SortOperationKind>
  productMinAmount?: Maybe<SortOperationKind>
  productText?: Maybe<SortOperationKind>
  state?: Maybe<StateSort>
  stateId?: Maybe<SortOperationKind>
  storeMaxAmount?: Maybe<SortOperationKind>
  storeMinAmount?: Maybe<SortOperationKind>
  updatedAt?: Maybe<SortOperationKind>
  url?: Maybe<SortOperationKind>
  webMaxAmount?: Maybe<SortOperationKind>
  webMinAmount?: Maybe<SortOperationKind>
}

export type StateSort = {
  abbreviation?: Maybe<SortOperationKind>
  body?: Maybe<SortOperationKind>
  brand?: Maybe<SortOperationKind>
  chatMeterWidgetCode?: Maybe<SortOperationKind>
  country?: Maybe<SortOperationKind>
  createdAt?: Maybe<SortOperationKind>
  disabledAt?: Maybe<SortOperationKind>
  flashAlert?: Maybe<SortOperationKind>
  h1?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
  imageUrl?: Maybe<SortOperationKind>
  loanInStore?: Maybe<SortOperationKind>
  loanOnline?: Maybe<SortOperationKind>
  metaDescription?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  title?: Maybe<SortOperationKind>
  updatedAt?: Maybe<SortOperationKind>
  urlSafeName?: Maybe<SortOperationKind>
}

export type Store = {
  __typename?: 'Store'
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  brand: Brands
  chatMeterLocationId?: Maybe<Scalars['String']>
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  city?: Maybe<City>
  cityId: Scalars['Int']
  createdAt: Scalars['DateTime']
  crossStreets?: Maybe<Scalars['String']>
  directionsText?: Maybe<Scalars['String']>
  directionsTitle?: Maybe<Scalars['String']>
  disabledAt?: Maybe<Scalars['DateTime']>
  displayCity?: Maybe<Scalars['String']>
  districtId?: Maybe<Scalars['Int']>
  districtManager?: Maybe<Scalars['String']>
  districtName?: Maybe<Scalars['String']>
  enableStoreLeads: Scalars['Boolean']
  facebookUrl?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  flashAlert?: Maybe<Scalars['String']>
  googleShortUrl?: Maybe<Scalars['String']>
  googleUrl?: Maybe<Scalars['String']>
  gPS?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  hours?: Maybe<Scalars['String']>
  id: Scalars['Int']
  licenceNumber?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  pageTemplate: Scalars['Int']
  phone?: Maybe<Scalars['String']>
  productMaxAmount: Scalars['Int']
  products?: Maybe<Array<Maybe<StoreProductPivot>>>
  publishedAt?: Maybe<Scalars['DateTime']>
  regionId?: Maybe<Scalars['Int']>
  regionName?: Maybe<Scalars['String']>
  storeEmail?: Maybe<Scalars['String']>
  storeImages?: Maybe<Array<Maybe<StoreImage>>>
  storeManager?: Maybe<Scalars['String']>
  storeOpenDate?: Maybe<Scalars['DateTime']>
  superStoreId?: Maybe<Scalars['Int']>
  temporarilyClosed: Scalars['Boolean']
  textGeneric?: Maybe<Scalars['String']>
  textGoogle?: Maybe<Scalars['String']>
  textYelp?: Maybe<Scalars['String']>
  textYP?: Maybe<Scalars['String']>
  timeZone?: Maybe<TimeZones>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
  variables?: Maybe<Array<Maybe<StoreCustomVariable>>>
  yahooUrl?: Maybe<Scalars['String']>
  yelpReviewUrl?: Maybe<Scalars['String']>
  yelpUrl?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type StoreProductMaxAmountArgs = {
  index: Scalars['Int']
}

/** A connection to a list of items. */
export type StoreConnection = {
  __typename?: 'StoreConnection'
  /** A list of edges. */
  edges?: Maybe<Array<StoreEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Store>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StoreCustomVariable = {
  __typename?: 'StoreCustomVariable'
  name?: Maybe<Scalars['String']>
  store?: Maybe<Store>
  storeId: Scalars['Int']
  value?: Maybe<Scalars['String']>
}

export type StoreCustomVariableFilter = {
  AND?: Maybe<Array<StoreCustomVariableFilter>>
  name?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<StoreCustomVariableFilter>>
  store?: Maybe<StoreFilter>
  storeId?: Maybe<Scalars['Int']>
  storeId_gt?: Maybe<Scalars['Int']>
  storeId_gte?: Maybe<Scalars['Int']>
  storeId_in?: Maybe<Array<Scalars['Int']>>
  storeId_lt?: Maybe<Scalars['Int']>
  storeId_lte?: Maybe<Scalars['Int']>
  storeId_not?: Maybe<Scalars['Int']>
  storeId_not_gt?: Maybe<Scalars['Int']>
  storeId_not_gte?: Maybe<Scalars['Int']>
  storeId_not_in?: Maybe<Array<Scalars['Int']>>
  storeId_not_lt?: Maybe<Scalars['Int']>
  storeId_not_lte?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['String']>
  value_contains?: Maybe<Scalars['String']>
  value_ends_with?: Maybe<Scalars['String']>
  value_in?: Maybe<Array<Maybe<Scalars['String']>>>
  value_not?: Maybe<Scalars['String']>
  value_not_contains?: Maybe<Scalars['String']>
  value_not_ends_with?: Maybe<Scalars['String']>
  value_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  value_not_starts_with?: Maybe<Scalars['String']>
  value_starts_with?: Maybe<Scalars['String']>
}

/** An edge in a connection. */
export type StoreEdge = {
  __typename?: 'StoreEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Store>
}

export type StoreFilter = {
  address1?: Maybe<Scalars['String']>
  address1_contains?: Maybe<Scalars['String']>
  address1_ends_with?: Maybe<Scalars['String']>
  address1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  address1_not?: Maybe<Scalars['String']>
  address1_not_contains?: Maybe<Scalars['String']>
  address1_not_ends_with?: Maybe<Scalars['String']>
  address1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  address1_not_starts_with?: Maybe<Scalars['String']>
  address1_starts_with?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  address2_contains?: Maybe<Scalars['String']>
  address2_ends_with?: Maybe<Scalars['String']>
  address2_in?: Maybe<Array<Maybe<Scalars['String']>>>
  address2_not?: Maybe<Scalars['String']>
  address2_not_contains?: Maybe<Scalars['String']>
  address2_not_ends_with?: Maybe<Scalars['String']>
  address2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  address2_not_starts_with?: Maybe<Scalars['String']>
  address2_starts_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<StoreFilter>>
  brand?: Maybe<Brands>
  brand_gt?: Maybe<Brands>
  brand_gte?: Maybe<Brands>
  brand_in?: Maybe<Array<Brands>>
  brand_lt?: Maybe<Brands>
  brand_lte?: Maybe<Brands>
  brand_not?: Maybe<Brands>
  brand_not_gt?: Maybe<Brands>
  brand_not_gte?: Maybe<Brands>
  brand_not_in?: Maybe<Array<Brands>>
  brand_not_lt?: Maybe<Brands>
  brand_not_lte?: Maybe<Brands>
  chatMeterLocationId?: Maybe<Scalars['String']>
  chatMeterLocationId_contains?: Maybe<Scalars['String']>
  chatMeterLocationId_ends_with?: Maybe<Scalars['String']>
  chatMeterLocationId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterLocationId_not?: Maybe<Scalars['String']>
  chatMeterLocationId_not_contains?: Maybe<Scalars['String']>
  chatMeterLocationId_not_ends_with?: Maybe<Scalars['String']>
  chatMeterLocationId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterLocationId_not_starts_with?: Maybe<Scalars['String']>
  chatMeterLocationId_starts_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode?: Maybe<Scalars['String']>
  chatMeterWidgetCode_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_contains?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_ends_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  chatMeterWidgetCode_not_starts_with?: Maybe<Scalars['String']>
  chatMeterWidgetCode_starts_with?: Maybe<Scalars['String']>
  city?: Maybe<CityFilter>
  cityId?: Maybe<Scalars['Int']>
  cityId_gt?: Maybe<Scalars['Int']>
  cityId_gte?: Maybe<Scalars['Int']>
  cityId_in?: Maybe<Array<Scalars['Int']>>
  cityId_lt?: Maybe<Scalars['Int']>
  cityId_lte?: Maybe<Scalars['Int']>
  cityId_not?: Maybe<Scalars['Int']>
  cityId_not_gt?: Maybe<Scalars['Int']>
  cityId_not_gte?: Maybe<Scalars['Int']>
  cityId_not_in?: Maybe<Array<Scalars['Int']>>
  cityId_not_lt?: Maybe<Scalars['Int']>
  cityId_not_lte?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_not_gt?: Maybe<Scalars['DateTime']>
  createdAt_not_gte?: Maybe<Scalars['DateTime']>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_lt?: Maybe<Scalars['DateTime']>
  createdAt_not_lte?: Maybe<Scalars['DateTime']>
  crossStreets?: Maybe<Scalars['String']>
  crossStreets_contains?: Maybe<Scalars['String']>
  crossStreets_ends_with?: Maybe<Scalars['String']>
  crossStreets_in?: Maybe<Array<Maybe<Scalars['String']>>>
  crossStreets_not?: Maybe<Scalars['String']>
  crossStreets_not_contains?: Maybe<Scalars['String']>
  crossStreets_not_ends_with?: Maybe<Scalars['String']>
  crossStreets_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  crossStreets_not_starts_with?: Maybe<Scalars['String']>
  crossStreets_starts_with?: Maybe<Scalars['String']>
  directionsText?: Maybe<Scalars['String']>
  directionsText_contains?: Maybe<Scalars['String']>
  directionsText_ends_with?: Maybe<Scalars['String']>
  directionsText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  directionsText_not?: Maybe<Scalars['String']>
  directionsText_not_contains?: Maybe<Scalars['String']>
  directionsText_not_ends_with?: Maybe<Scalars['String']>
  directionsText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  directionsText_not_starts_with?: Maybe<Scalars['String']>
  directionsText_starts_with?: Maybe<Scalars['String']>
  directionsTitle?: Maybe<Scalars['String']>
  directionsTitle_contains?: Maybe<Scalars['String']>
  directionsTitle_ends_with?: Maybe<Scalars['String']>
  directionsTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  directionsTitle_not?: Maybe<Scalars['String']>
  directionsTitle_not_contains?: Maybe<Scalars['String']>
  directionsTitle_not_ends_with?: Maybe<Scalars['String']>
  directionsTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  directionsTitle_not_starts_with?: Maybe<Scalars['String']>
  directionsTitle_starts_with?: Maybe<Scalars['String']>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  displayCity?: Maybe<Scalars['String']>
  displayCity_contains?: Maybe<Scalars['String']>
  displayCity_ends_with?: Maybe<Scalars['String']>
  displayCity_in?: Maybe<Array<Maybe<Scalars['String']>>>
  displayCity_not?: Maybe<Scalars['String']>
  displayCity_not_contains?: Maybe<Scalars['String']>
  displayCity_not_ends_with?: Maybe<Scalars['String']>
  displayCity_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  displayCity_not_starts_with?: Maybe<Scalars['String']>
  displayCity_starts_with?: Maybe<Scalars['String']>
  districtId?: Maybe<Scalars['Int']>
  districtId_gt?: Maybe<Scalars['Int']>
  districtId_gte?: Maybe<Scalars['Int']>
  districtId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  districtId_lt?: Maybe<Scalars['Int']>
  districtId_lte?: Maybe<Scalars['Int']>
  districtId_not?: Maybe<Scalars['Int']>
  districtId_not_gt?: Maybe<Scalars['Int']>
  districtId_not_gte?: Maybe<Scalars['Int']>
  districtId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  districtId_not_lt?: Maybe<Scalars['Int']>
  districtId_not_lte?: Maybe<Scalars['Int']>
  districtManager?: Maybe<Scalars['String']>
  districtManager_contains?: Maybe<Scalars['String']>
  districtManager_ends_with?: Maybe<Scalars['String']>
  districtManager_in?: Maybe<Array<Maybe<Scalars['String']>>>
  districtManager_not?: Maybe<Scalars['String']>
  districtManager_not_contains?: Maybe<Scalars['String']>
  districtManager_not_ends_with?: Maybe<Scalars['String']>
  districtManager_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  districtManager_not_starts_with?: Maybe<Scalars['String']>
  districtManager_starts_with?: Maybe<Scalars['String']>
  districtName?: Maybe<Scalars['String']>
  districtName_contains?: Maybe<Scalars['String']>
  districtName_ends_with?: Maybe<Scalars['String']>
  districtName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  districtName_not?: Maybe<Scalars['String']>
  districtName_not_contains?: Maybe<Scalars['String']>
  districtName_not_ends_with?: Maybe<Scalars['String']>
  districtName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  districtName_not_starts_with?: Maybe<Scalars['String']>
  districtName_starts_with?: Maybe<Scalars['String']>
  enableStoreLeads?: Maybe<Scalars['Boolean']>
  enableStoreLeads_not?: Maybe<Scalars['Boolean']>
  facebookUrl?: Maybe<Scalars['String']>
  facebookUrl_contains?: Maybe<Scalars['String']>
  facebookUrl_ends_with?: Maybe<Scalars['String']>
  facebookUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_not?: Maybe<Scalars['String']>
  facebookUrl_not_contains?: Maybe<Scalars['String']>
  facebookUrl_not_ends_with?: Maybe<Scalars['String']>
  facebookUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_not_starts_with?: Maybe<Scalars['String']>
  facebookUrl_starts_with?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  fax_contains?: Maybe<Scalars['String']>
  fax_ends_with?: Maybe<Scalars['String']>
  fax_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fax_not?: Maybe<Scalars['String']>
  fax_not_contains?: Maybe<Scalars['String']>
  fax_not_ends_with?: Maybe<Scalars['String']>
  fax_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fax_not_starts_with?: Maybe<Scalars['String']>
  fax_starts_with?: Maybe<Scalars['String']>
  flashAlert?: Maybe<Scalars['String']>
  flashAlert_contains?: Maybe<Scalars['String']>
  flashAlert_ends_with?: Maybe<Scalars['String']>
  flashAlert_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not?: Maybe<Scalars['String']>
  flashAlert_not_contains?: Maybe<Scalars['String']>
  flashAlert_not_ends_with?: Maybe<Scalars['String']>
  flashAlert_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  flashAlert_not_starts_with?: Maybe<Scalars['String']>
  flashAlert_starts_with?: Maybe<Scalars['String']>
  googleShortUrl?: Maybe<Scalars['String']>
  googleShortUrl_contains?: Maybe<Scalars['String']>
  googleShortUrl_ends_with?: Maybe<Scalars['String']>
  googleShortUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  googleShortUrl_not?: Maybe<Scalars['String']>
  googleShortUrl_not_contains?: Maybe<Scalars['String']>
  googleShortUrl_not_ends_with?: Maybe<Scalars['String']>
  googleShortUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  googleShortUrl_not_starts_with?: Maybe<Scalars['String']>
  googleShortUrl_starts_with?: Maybe<Scalars['String']>
  googleUrl?: Maybe<Scalars['String']>
  googleUrl_contains?: Maybe<Scalars['String']>
  googleUrl_ends_with?: Maybe<Scalars['String']>
  googleUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  googleUrl_not?: Maybe<Scalars['String']>
  googleUrl_not_contains?: Maybe<Scalars['String']>
  googleUrl_not_ends_with?: Maybe<Scalars['String']>
  googleUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  googleUrl_not_starts_with?: Maybe<Scalars['String']>
  googleUrl_starts_with?: Maybe<Scalars['String']>
  gPS?: Maybe<Scalars['String']>
  gPS_contains?: Maybe<Scalars['String']>
  gPS_ends_with?: Maybe<Scalars['String']>
  gPS_in?: Maybe<Array<Maybe<Scalars['String']>>>
  gPS_not?: Maybe<Scalars['String']>
  gPS_not_contains?: Maybe<Scalars['String']>
  gPS_not_ends_with?: Maybe<Scalars['String']>
  gPS_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  gPS_not_starts_with?: Maybe<Scalars['String']>
  gPS_starts_with?: Maybe<Scalars['String']>
  h1?: Maybe<Scalars['String']>
  h1_contains?: Maybe<Scalars['String']>
  h1_ends_with?: Maybe<Scalars['String']>
  h1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not?: Maybe<Scalars['String']>
  h1_not_contains?: Maybe<Scalars['String']>
  h1_not_ends_with?: Maybe<Scalars['String']>
  h1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  h1_not_starts_with?: Maybe<Scalars['String']>
  h1_starts_with?: Maybe<Scalars['String']>
  hours?: Maybe<Scalars['String']>
  hours_contains?: Maybe<Scalars['String']>
  hours_ends_with?: Maybe<Scalars['String']>
  hours_in?: Maybe<Array<Maybe<Scalars['String']>>>
  hours_not?: Maybe<Scalars['String']>
  hours_not_contains?: Maybe<Scalars['String']>
  hours_not_ends_with?: Maybe<Scalars['String']>
  hours_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  hours_not_starts_with?: Maybe<Scalars['String']>
  hours_starts_with?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  licenceNumber?: Maybe<Scalars['String']>
  licenceNumber_contains?: Maybe<Scalars['String']>
  licenceNumber_ends_with?: Maybe<Scalars['String']>
  licenceNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>
  licenceNumber_not?: Maybe<Scalars['String']>
  licenceNumber_not_contains?: Maybe<Scalars['String']>
  licenceNumber_not_ends_with?: Maybe<Scalars['String']>
  licenceNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  licenceNumber_not_starts_with?: Maybe<Scalars['String']>
  licenceNumber_starts_with?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaDescription_contains?: Maybe<Scalars['String']>
  metaDescription_ends_with?: Maybe<Scalars['String']>
  metaDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not?: Maybe<Scalars['String']>
  metaDescription_not_contains?: Maybe<Scalars['String']>
  metaDescription_not_ends_with?: Maybe<Scalars['String']>
  metaDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  metaDescription_not_starts_with?: Maybe<Scalars['String']>
  metaDescription_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<StoreFilter>>
  pageTemplate?: Maybe<Scalars['Int']>
  pageTemplate_gt?: Maybe<Scalars['Int']>
  pageTemplate_gte?: Maybe<Scalars['Int']>
  pageTemplate_in?: Maybe<Array<Scalars['Int']>>
  pageTemplate_lt?: Maybe<Scalars['Int']>
  pageTemplate_lte?: Maybe<Scalars['Int']>
  pageTemplate_not?: Maybe<Scalars['Int']>
  pageTemplate_not_gt?: Maybe<Scalars['Int']>
  pageTemplate_not_gte?: Maybe<Scalars['Int']>
  pageTemplate_not_in?: Maybe<Array<Scalars['Int']>>
  pageTemplate_not_lt?: Maybe<Scalars['Int']>
  pageTemplate_not_lte?: Maybe<Scalars['Int']>
  phone?: Maybe<Scalars['String']>
  phone_contains?: Maybe<Scalars['String']>
  phone_ends_with?: Maybe<Scalars['String']>
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phone_not?: Maybe<Scalars['String']>
  phone_not_contains?: Maybe<Scalars['String']>
  phone_not_ends_with?: Maybe<Scalars['String']>
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phone_not_starts_with?: Maybe<Scalars['String']>
  phone_starts_with?: Maybe<Scalars['String']>
  products_all?: Maybe<StoreProductPivotFilter>
  products_any?: Maybe<Scalars['Boolean']>
  products_none?: Maybe<StoreProductPivotFilter>
  products_some?: Maybe<StoreProductPivotFilter>
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  publishedAt_not?: Maybe<Scalars['DateTime']>
  publishedAt_not_gt?: Maybe<Scalars['DateTime']>
  publishedAt_not_gte?: Maybe<Scalars['DateTime']>
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  publishedAt_not_lt?: Maybe<Scalars['DateTime']>
  publishedAt_not_lte?: Maybe<Scalars['DateTime']>
  regionId?: Maybe<Scalars['Int']>
  regionId_gt?: Maybe<Scalars['Int']>
  regionId_gte?: Maybe<Scalars['Int']>
  regionId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  regionId_lt?: Maybe<Scalars['Int']>
  regionId_lte?: Maybe<Scalars['Int']>
  regionId_not?: Maybe<Scalars['Int']>
  regionId_not_gt?: Maybe<Scalars['Int']>
  regionId_not_gte?: Maybe<Scalars['Int']>
  regionId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  regionId_not_lt?: Maybe<Scalars['Int']>
  regionId_not_lte?: Maybe<Scalars['Int']>
  regionName?: Maybe<Scalars['String']>
  regionName_contains?: Maybe<Scalars['String']>
  regionName_ends_with?: Maybe<Scalars['String']>
  regionName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  regionName_not?: Maybe<Scalars['String']>
  regionName_not_contains?: Maybe<Scalars['String']>
  regionName_not_ends_with?: Maybe<Scalars['String']>
  regionName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  regionName_not_starts_with?: Maybe<Scalars['String']>
  regionName_starts_with?: Maybe<Scalars['String']>
  storeEmail?: Maybe<Scalars['String']>
  storeEmail_contains?: Maybe<Scalars['String']>
  storeEmail_ends_with?: Maybe<Scalars['String']>
  storeEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>
  storeEmail_not?: Maybe<Scalars['String']>
  storeEmail_not_contains?: Maybe<Scalars['String']>
  storeEmail_not_ends_with?: Maybe<Scalars['String']>
  storeEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  storeEmail_not_starts_with?: Maybe<Scalars['String']>
  storeEmail_starts_with?: Maybe<Scalars['String']>
  storeImages_all?: Maybe<StoreImageFilter>
  storeImages_any?: Maybe<Scalars['Boolean']>
  storeImages_none?: Maybe<StoreImageFilter>
  storeImages_some?: Maybe<StoreImageFilter>
  storeManager?: Maybe<Scalars['String']>
  storeManager_contains?: Maybe<Scalars['String']>
  storeManager_ends_with?: Maybe<Scalars['String']>
  storeManager_in?: Maybe<Array<Maybe<Scalars['String']>>>
  storeManager_not?: Maybe<Scalars['String']>
  storeManager_not_contains?: Maybe<Scalars['String']>
  storeManager_not_ends_with?: Maybe<Scalars['String']>
  storeManager_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  storeManager_not_starts_with?: Maybe<Scalars['String']>
  storeManager_starts_with?: Maybe<Scalars['String']>
  storeOpenDate?: Maybe<Scalars['DateTime']>
  storeOpenDate_gt?: Maybe<Scalars['DateTime']>
  storeOpenDate_gte?: Maybe<Scalars['DateTime']>
  storeOpenDate_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  storeOpenDate_lt?: Maybe<Scalars['DateTime']>
  storeOpenDate_lte?: Maybe<Scalars['DateTime']>
  storeOpenDate_not?: Maybe<Scalars['DateTime']>
  storeOpenDate_not_gt?: Maybe<Scalars['DateTime']>
  storeOpenDate_not_gte?: Maybe<Scalars['DateTime']>
  storeOpenDate_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  storeOpenDate_not_lt?: Maybe<Scalars['DateTime']>
  storeOpenDate_not_lte?: Maybe<Scalars['DateTime']>
  superStoreId?: Maybe<Scalars['Int']>
  superStoreId_gt?: Maybe<Scalars['Int']>
  superStoreId_gte?: Maybe<Scalars['Int']>
  superStoreId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  superStoreId_lt?: Maybe<Scalars['Int']>
  superStoreId_lte?: Maybe<Scalars['Int']>
  superStoreId_not?: Maybe<Scalars['Int']>
  superStoreId_not_gt?: Maybe<Scalars['Int']>
  superStoreId_not_gte?: Maybe<Scalars['Int']>
  superStoreId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  superStoreId_not_lt?: Maybe<Scalars['Int']>
  superStoreId_not_lte?: Maybe<Scalars['Int']>
  temporarilyClosed?: Maybe<Scalars['Boolean']>
  temporarilyClosed_not?: Maybe<Scalars['Boolean']>
  textGeneric?: Maybe<Scalars['String']>
  textGeneric_contains?: Maybe<Scalars['String']>
  textGeneric_ends_with?: Maybe<Scalars['String']>
  textGeneric_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textGeneric_not?: Maybe<Scalars['String']>
  textGeneric_not_contains?: Maybe<Scalars['String']>
  textGeneric_not_ends_with?: Maybe<Scalars['String']>
  textGeneric_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textGeneric_not_starts_with?: Maybe<Scalars['String']>
  textGeneric_starts_with?: Maybe<Scalars['String']>
  textGoogle?: Maybe<Scalars['String']>
  textGoogle_contains?: Maybe<Scalars['String']>
  textGoogle_ends_with?: Maybe<Scalars['String']>
  textGoogle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textGoogle_not?: Maybe<Scalars['String']>
  textGoogle_not_contains?: Maybe<Scalars['String']>
  textGoogle_not_ends_with?: Maybe<Scalars['String']>
  textGoogle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textGoogle_not_starts_with?: Maybe<Scalars['String']>
  textGoogle_starts_with?: Maybe<Scalars['String']>
  textYelp?: Maybe<Scalars['String']>
  textYelp_contains?: Maybe<Scalars['String']>
  textYelp_ends_with?: Maybe<Scalars['String']>
  textYelp_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textYelp_not?: Maybe<Scalars['String']>
  textYelp_not_contains?: Maybe<Scalars['String']>
  textYelp_not_ends_with?: Maybe<Scalars['String']>
  textYelp_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textYelp_not_starts_with?: Maybe<Scalars['String']>
  textYelp_starts_with?: Maybe<Scalars['String']>
  textYP?: Maybe<Scalars['String']>
  textYP_contains?: Maybe<Scalars['String']>
  textYP_ends_with?: Maybe<Scalars['String']>
  textYP_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textYP_not?: Maybe<Scalars['String']>
  textYP_not_contains?: Maybe<Scalars['String']>
  textYP_not_ends_with?: Maybe<Scalars['String']>
  textYP_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  textYP_not_starts_with?: Maybe<Scalars['String']>
  textYP_starts_with?: Maybe<Scalars['String']>
  timeZone?: Maybe<TimeZones>
  timeZone_gt?: Maybe<TimeZones>
  timeZone_gte?: Maybe<TimeZones>
  timeZone_in?: Maybe<Array<Maybe<TimeZones>>>
  timeZone_lt?: Maybe<TimeZones>
  timeZone_lte?: Maybe<TimeZones>
  timeZone_not?: Maybe<TimeZones>
  timeZone_not_gt?: Maybe<TimeZones>
  timeZone_not_gte?: Maybe<TimeZones>
  timeZone_not_in?: Maybe<Array<Maybe<TimeZones>>>
  timeZone_not_lt?: Maybe<TimeZones>
  timeZone_not_lte?: Maybe<TimeZones>
  title?: Maybe<Scalars['String']>
  title_contains?: Maybe<Scalars['String']>
  title_ends_with?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  title_not_ends_with?: Maybe<Scalars['String']>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_starts_with?: Maybe<Scalars['String']>
  title_starts_with?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
  url?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
  variables_all?: Maybe<StoreCustomVariableFilter>
  variables_any?: Maybe<Scalars['Boolean']>
  variables_none?: Maybe<StoreCustomVariableFilter>
  variables_some?: Maybe<StoreCustomVariableFilter>
  yahooUrl?: Maybe<Scalars['String']>
  yahooUrl_contains?: Maybe<Scalars['String']>
  yahooUrl_ends_with?: Maybe<Scalars['String']>
  yahooUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yahooUrl_not?: Maybe<Scalars['String']>
  yahooUrl_not_contains?: Maybe<Scalars['String']>
  yahooUrl_not_ends_with?: Maybe<Scalars['String']>
  yahooUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yahooUrl_not_starts_with?: Maybe<Scalars['String']>
  yahooUrl_starts_with?: Maybe<Scalars['String']>
  yelpReviewUrl?: Maybe<Scalars['String']>
  yelpReviewUrl_contains?: Maybe<Scalars['String']>
  yelpReviewUrl_ends_with?: Maybe<Scalars['String']>
  yelpReviewUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yelpReviewUrl_not?: Maybe<Scalars['String']>
  yelpReviewUrl_not_contains?: Maybe<Scalars['String']>
  yelpReviewUrl_not_ends_with?: Maybe<Scalars['String']>
  yelpReviewUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yelpReviewUrl_not_starts_with?: Maybe<Scalars['String']>
  yelpReviewUrl_starts_with?: Maybe<Scalars['String']>
  yelpUrl?: Maybe<Scalars['String']>
  yelpUrl_contains?: Maybe<Scalars['String']>
  yelpUrl_ends_with?: Maybe<Scalars['String']>
  yelpUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yelpUrl_not?: Maybe<Scalars['String']>
  yelpUrl_not_contains?: Maybe<Scalars['String']>
  yelpUrl_not_ends_with?: Maybe<Scalars['String']>
  yelpUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  yelpUrl_not_starts_with?: Maybe<Scalars['String']>
  yelpUrl_starts_with?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  zip_contains?: Maybe<Scalars['String']>
  zip_ends_with?: Maybe<Scalars['String']>
  zip_in?: Maybe<Array<Maybe<Scalars['String']>>>
  zip_not?: Maybe<Scalars['String']>
  zip_not_contains?: Maybe<Scalars['String']>
  zip_not_ends_with?: Maybe<Scalars['String']>
  zip_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  zip_not_starts_with?: Maybe<Scalars['String']>
  zip_starts_with?: Maybe<Scalars['String']>
}

export type StoreImage = {
  __typename?: 'StoreImage'
  altText?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  disabledAt?: Maybe<Scalars['DateTime']>
  filename?: Maybe<Scalars['String']>
  id: Scalars['Int']
  orderIndex?: Maybe<Scalars['Int']>
  store?: Maybe<Store>
  storeId?: Maybe<Scalars['Int']>
  thumbnailUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type StoreImageFilter = {
  altText?: Maybe<Scalars['String']>
  altText_contains?: Maybe<Scalars['String']>
  altText_ends_with?: Maybe<Scalars['String']>
  altText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  altText_not?: Maybe<Scalars['String']>
  altText_not_contains?: Maybe<Scalars['String']>
  altText_not_ends_with?: Maybe<Scalars['String']>
  altText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  altText_not_starts_with?: Maybe<Scalars['String']>
  altText_starts_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<StoreImageFilter>>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_not_gt?: Maybe<Scalars['DateTime']>
  createdAt_not_gte?: Maybe<Scalars['DateTime']>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_lt?: Maybe<Scalars['DateTime']>
  createdAt_not_lte?: Maybe<Scalars['DateTime']>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  filename?: Maybe<Scalars['String']>
  filename_contains?: Maybe<Scalars['String']>
  filename_ends_with?: Maybe<Scalars['String']>
  filename_in?: Maybe<Array<Maybe<Scalars['String']>>>
  filename_not?: Maybe<Scalars['String']>
  filename_not_contains?: Maybe<Scalars['String']>
  filename_not_ends_with?: Maybe<Scalars['String']>
  filename_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  filename_not_starts_with?: Maybe<Scalars['String']>
  filename_starts_with?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  OR?: Maybe<Array<StoreImageFilter>>
  orderIndex?: Maybe<Scalars['Int']>
  orderIndex_gt?: Maybe<Scalars['Int']>
  orderIndex_gte?: Maybe<Scalars['Int']>
  orderIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_lt?: Maybe<Scalars['Int']>
  orderIndex_lte?: Maybe<Scalars['Int']>
  orderIndex_not?: Maybe<Scalars['Int']>
  orderIndex_not_gt?: Maybe<Scalars['Int']>
  orderIndex_not_gte?: Maybe<Scalars['Int']>
  orderIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_not_lt?: Maybe<Scalars['Int']>
  orderIndex_not_lte?: Maybe<Scalars['Int']>
  store?: Maybe<StoreFilter>
  storeId?: Maybe<Scalars['Int']>
  storeId_gt?: Maybe<Scalars['Int']>
  storeId_gte?: Maybe<Scalars['Int']>
  storeId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeId_lt?: Maybe<Scalars['Int']>
  storeId_lte?: Maybe<Scalars['Int']>
  storeId_not?: Maybe<Scalars['Int']>
  storeId_not_gt?: Maybe<Scalars['Int']>
  storeId_not_gte?: Maybe<Scalars['Int']>
  storeId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storeId_not_lt?: Maybe<Scalars['Int']>
  storeId_not_lte?: Maybe<Scalars['Int']>
  thumbnailUrl?: Maybe<Scalars['String']>
  thumbnailUrl_contains?: Maybe<Scalars['String']>
  thumbnailUrl_ends_with?: Maybe<Scalars['String']>
  thumbnailUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  thumbnailUrl_not?: Maybe<Scalars['String']>
  thumbnailUrl_not_contains?: Maybe<Scalars['String']>
  thumbnailUrl_not_ends_with?: Maybe<Scalars['String']>
  thumbnailUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  thumbnailUrl_not_starts_with?: Maybe<Scalars['String']>
  thumbnailUrl_starts_with?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
  url?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
}

export type StoreProductPivot = {
  __typename?: 'StoreProductPivot'
  disabledAt?: Maybe<Scalars['DateTime']>
  id: Scalars['Int']
  orderIndex?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  productId: Scalars['Int']
  productText?: Maybe<Scalars['String']>
  stateProduct?: Maybe<StateProductPivot>
  stateProductPivotId?: Maybe<Scalars['Int']>
  store?: Maybe<Store>
  storeId: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

export type StoreProductPivotFilter = {
  AND?: Maybe<Array<StoreProductPivotFilter>>
  disabledAt?: Maybe<Scalars['DateTime']>
  disabledAt_gt?: Maybe<Scalars['DateTime']>
  disabledAt_gte?: Maybe<Scalars['DateTime']>
  disabledAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_lt?: Maybe<Scalars['DateTime']>
  disabledAt_lte?: Maybe<Scalars['DateTime']>
  disabledAt_not?: Maybe<Scalars['DateTime']>
  disabledAt_not_gt?: Maybe<Scalars['DateTime']>
  disabledAt_not_gte?: Maybe<Scalars['DateTime']>
  disabledAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  disabledAt_not_lt?: Maybe<Scalars['DateTime']>
  disabledAt_not_lte?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['Int']>
  id_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_not_lt?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  OR?: Maybe<Array<StoreProductPivotFilter>>
  orderIndex?: Maybe<Scalars['Int']>
  orderIndex_gt?: Maybe<Scalars['Int']>
  orderIndex_gte?: Maybe<Scalars['Int']>
  orderIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_lt?: Maybe<Scalars['Int']>
  orderIndex_lte?: Maybe<Scalars['Int']>
  orderIndex_not?: Maybe<Scalars['Int']>
  orderIndex_not_gt?: Maybe<Scalars['Int']>
  orderIndex_not_gte?: Maybe<Scalars['Int']>
  orderIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  orderIndex_not_lt?: Maybe<Scalars['Int']>
  orderIndex_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  productId?: Maybe<Scalars['Int']>
  productId_gt?: Maybe<Scalars['Int']>
  productId_gte?: Maybe<Scalars['Int']>
  productId_in?: Maybe<Array<Scalars['Int']>>
  productId_lt?: Maybe<Scalars['Int']>
  productId_lte?: Maybe<Scalars['Int']>
  productId_not?: Maybe<Scalars['Int']>
  productId_not_gt?: Maybe<Scalars['Int']>
  productId_not_gte?: Maybe<Scalars['Int']>
  productId_not_in?: Maybe<Array<Scalars['Int']>>
  productId_not_lt?: Maybe<Scalars['Int']>
  productId_not_lte?: Maybe<Scalars['Int']>
  productText?: Maybe<Scalars['String']>
  productText_contains?: Maybe<Scalars['String']>
  productText_ends_with?: Maybe<Scalars['String']>
  productText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productText_not?: Maybe<Scalars['String']>
  productText_not_contains?: Maybe<Scalars['String']>
  productText_not_ends_with?: Maybe<Scalars['String']>
  productText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productText_not_starts_with?: Maybe<Scalars['String']>
  productText_starts_with?: Maybe<Scalars['String']>
  stateProduct?: Maybe<StateProductPivotFilter>
  stateProductPivotId?: Maybe<Scalars['Int']>
  stateProductPivotId_gt?: Maybe<Scalars['Int']>
  stateProductPivotId_gte?: Maybe<Scalars['Int']>
  stateProductPivotId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stateProductPivotId_lt?: Maybe<Scalars['Int']>
  stateProductPivotId_lte?: Maybe<Scalars['Int']>
  stateProductPivotId_not?: Maybe<Scalars['Int']>
  stateProductPivotId_not_gt?: Maybe<Scalars['Int']>
  stateProductPivotId_not_gte?: Maybe<Scalars['Int']>
  stateProductPivotId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stateProductPivotId_not_lt?: Maybe<Scalars['Int']>
  stateProductPivotId_not_lte?: Maybe<Scalars['Int']>
  store?: Maybe<StoreFilter>
  storeId?: Maybe<Scalars['Int']>
  storeId_gt?: Maybe<Scalars['Int']>
  storeId_gte?: Maybe<Scalars['Int']>
  storeId_in?: Maybe<Array<Scalars['Int']>>
  storeId_lt?: Maybe<Scalars['Int']>
  storeId_lte?: Maybe<Scalars['Int']>
  storeId_not?: Maybe<Scalars['Int']>
  storeId_not_gt?: Maybe<Scalars['Int']>
  storeId_not_gte?: Maybe<Scalars['Int']>
  storeId_not_in?: Maybe<Array<Scalars['Int']>>
  storeId_not_lt?: Maybe<Scalars['Int']>
  storeId_not_lte?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_not_gt?: Maybe<Scalars['DateTime']>
  updatedAt_not_gte?: Maybe<Scalars['DateTime']>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_lt?: Maybe<Scalars['DateTime']>
  updatedAt_not_lte?: Maybe<Scalars['DateTime']>
}

export type StoreSort = {
  address1?: Maybe<SortOperationKind>
  address2?: Maybe<SortOperationKind>
  brand?: Maybe<SortOperationKind>
  chatMeterLocationId?: Maybe<SortOperationKind>
  chatMeterWidgetCode?: Maybe<SortOperationKind>
  city?: Maybe<CitySort>
  cityId?: Maybe<SortOperationKind>
  createdAt?: Maybe<SortOperationKind>
  crossStreets?: Maybe<SortOperationKind>
  directionsText?: Maybe<SortOperationKind>
  directionsTitle?: Maybe<SortOperationKind>
  disabledAt?: Maybe<SortOperationKind>
  displayCity?: Maybe<SortOperationKind>
  districtId?: Maybe<SortOperationKind>
  districtManager?: Maybe<SortOperationKind>
  districtName?: Maybe<SortOperationKind>
  enableStoreLeads?: Maybe<SortOperationKind>
  facebookUrl?: Maybe<SortOperationKind>
  fax?: Maybe<SortOperationKind>
  flashAlert?: Maybe<SortOperationKind>
  googleShortUrl?: Maybe<SortOperationKind>
  googleUrl?: Maybe<SortOperationKind>
  gPS?: Maybe<SortOperationKind>
  h1?: Maybe<SortOperationKind>
  hours?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
  licenceNumber?: Maybe<SortOperationKind>
  metaDescription?: Maybe<SortOperationKind>
  pageTemplate?: Maybe<SortOperationKind>
  phone?: Maybe<SortOperationKind>
  publishedAt?: Maybe<SortOperationKind>
  regionId?: Maybe<SortOperationKind>
  regionName?: Maybe<SortOperationKind>
  storeEmail?: Maybe<SortOperationKind>
  storeManager?: Maybe<SortOperationKind>
  storeOpenDate?: Maybe<SortOperationKind>
  superStoreId?: Maybe<SortOperationKind>
  temporarilyClosed?: Maybe<SortOperationKind>
  textGeneric?: Maybe<SortOperationKind>
  textGoogle?: Maybe<SortOperationKind>
  textYelp?: Maybe<SortOperationKind>
  textYP?: Maybe<SortOperationKind>
  timeZone?: Maybe<SortOperationKind>
  title?: Maybe<SortOperationKind>
  updatedAt?: Maybe<SortOperationKind>
  url?: Maybe<SortOperationKind>
  yahooUrl?: Maybe<SortOperationKind>
  yelpReviewUrl?: Maybe<SortOperationKind>
  yelpUrl?: Maybe<SortOperationKind>
  zip?: Maybe<SortOperationKind>
}

export type Theme = {
  __typename?: 'Theme'
  articles?: Maybe<Array<Maybe<Article>>>
  categories?: Maybe<Array<Maybe<Category>>>
  iD: Scalars['Int']
  name?: Maybe<Scalars['String']>
  style?: Maybe<Scalars['String']>
}

export type ThemeFilter = {
  AND?: Maybe<Array<ThemeFilter>>
  articles_all?: Maybe<ArticleFilter>
  articles_any?: Maybe<Scalars['Boolean']>
  articles_none?: Maybe<ArticleFilter>
  articles_some?: Maybe<ArticleFilter>
  categories_all?: Maybe<CategoryFilter>
  categories_any?: Maybe<Scalars['Boolean']>
  categories_none?: Maybe<CategoryFilter>
  categories_some?: Maybe<CategoryFilter>
  iD?: Maybe<Scalars['Int']>
  iD_gt?: Maybe<Scalars['Int']>
  iD_gte?: Maybe<Scalars['Int']>
  iD_in?: Maybe<Array<Scalars['Int']>>
  iD_lt?: Maybe<Scalars['Int']>
  iD_lte?: Maybe<Scalars['Int']>
  iD_not?: Maybe<Scalars['Int']>
  iD_not_gt?: Maybe<Scalars['Int']>
  iD_not_gte?: Maybe<Scalars['Int']>
  iD_not_in?: Maybe<Array<Scalars['Int']>>
  iD_not_lt?: Maybe<Scalars['Int']>
  iD_not_lte?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  OR?: Maybe<Array<ThemeFilter>>
  style?: Maybe<Scalars['String']>
  style_contains?: Maybe<Scalars['String']>
  style_ends_with?: Maybe<Scalars['String']>
  style_in?: Maybe<Array<Maybe<Scalars['String']>>>
  style_not?: Maybe<Scalars['String']>
  style_not_contains?: Maybe<Scalars['String']>
  style_not_ends_with?: Maybe<Scalars['String']>
  style_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  style_not_starts_with?: Maybe<Scalars['String']>
  style_starts_with?: Maybe<Scalars['String']>
}

export type ThemeSort = {
  iD?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  style?: Maybe<SortOperationKind>
}

export enum TimeZones {
  Pacific = 'PACIFIC',
  Mountain = 'MOUNTAIN',
  Central = 'CENTRAL',
  Eastern = 'EASTERN',
  Atlantic = 'ATLANTIC',
  Newfoundland = 'NEWFOUNDLAND',
}

export type WebpageContent = {
  __typename?: 'WebpageContent'
  content?: Maybe<Scalars['String']>
}
