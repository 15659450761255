import * as React from "react";
import {
  ProductProvider,
  ProductContextConfig,
} from "./src/cobra-products/contexts/productContext";
import { ParallaxProvider } from "react-scroll-parallax";

// interface RootElement {
//   element: JSX.Element
// }

export const wrapRootElement = (
  { element } /*: RootElement*/,
  pluginOptions /*: ProductContextConfig*/
) => {
  return (
    <ProductProvider {...pluginOptions}>
      <ParallaxProvider>{element}</ParallaxProvider>
    </ProductProvider>
  );
};
