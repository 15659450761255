import { ThemeUIStyleObject } from "theme-ui";

export const text: Record<string, ThemeUIStyleObject> = {
  title: {
    fontSize: 1,
    textTransform: "uppercase",
    color: "secondary",
    lineHeight: 1.5,
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  h1: {
    variant: "text.heading",
    color: "secondary",
    fontSize: [7, null, 8], //48px 54px
    textAlign: "center",
  },
  /* H1 Header Regular */
  h1HeaderRegular: {
    color: "#000",
    lineHeight: ["45px", null, null, "55px"],
    fontSize: ["40px", null, null, null, "45px"],
    textAlign: ["center", null, null, "left"],
    fontWeight: 300,
  },
  h2HeaderSemiBold: {
    fontSize: "22px",
    textAlign: ["center", null, null, "left"],
    fontWeight: 600,
  },
  h2: {
    variant: "text.heading",
    color: "primary",
    fontSize: 6, //36px
    textAlign: "center",
  },
  h1InfoText: {
    fontSize: ["45px", null, null, null, "54px"],
    fontWeight: 700,
  },
  h2New: {
    // variant: "text.heading",
    color: "black",
    fontSize: "22px", //36px
    textAlign: "center",
    fontWeight: 700,
  },
  bodyNew: {
    fontWeight: 600,
    fontSize: 1,
    color: "black",
    lineHeight: "24px",
  },
  h3: {
    variant: "text.heading",
    color: "primary",
    fontSize: 5, //32px
  },
  h4: {
    variant: "text.heading",
    color: "secondary",
    fontSize: 4, //26px
  },
  h5: {
    color: "secondary",
    variant: "text.heading",
    fontSize: [2, null, 3], // 20px  22px
    lineHeight: 1.5,
  },
  body: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
    letterSpacing: "body",
    color: "text",
  },
  basic: {
    variant: "text.body",
    fontSize: 1,
    lineHeight: 1.7,
  },
  disclaimer: {
    variant: "text.body",
    fontSize: 12,
    fontWeight: 600,
    color: "brand.black",
  },
  link: {
    textDecoration: "underline",
    color: "primary",
  },
};
