module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CashMoney","short_name":"CashMoney","description":"Cash Money offers lines of credit and payday loans both online and in store. Get the cash you need fast with our convenient loan stores near you in Canada.","start_url":"/?utm_source=installed","theme_color":"#232C3A","display":"standalone","icon":"src/images/favicon.svg","icon_options":{"purpose":"maskable badge any"},"categories":["finance","business"],"dir":"ltr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c3bf0fc29adf46b14d96cc63e04e25ac"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KNJ3BP","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.cashmoney.ca","stripQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"aqw7thq"}},
    },{
      plugin: require('../../cobra-find-a-store/gatsby-browser.js'),
      options: {"plugins":[],"brands":[2],"mapCenter":{"lat":54.493436,"lng":-96.4842465},"mapZoom":4,"baseUrl":"https://www.cashmoney.ca","title":"Payday Loan Store Locations - Cash Money","metaDescription":"Cash Money has stores throughout Canada. Find a payday loan store with our online store locator or call (877) 987-2274 to find a store near you."},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
