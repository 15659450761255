import { ThemeUIStyleObject } from "theme-ui";

const base: ThemeUIStyleObject = {
  appearance: "none",
  outline: "none",
  cursor: "pointer",
  display: "inline-block",
  textAlign: "center",
  lineHeight: "inherit",
  textDecoration: "none",
  fontSize: "inherit",
  fontWeight: "bold",
  fontFamily: "body",
  m: 0,
  px: 3,
  py: 2,
  border: 0,
  transitionProperty: "opacity, box-shadow, transform, background-color",
  transitionDuration: "150ms",
  transitionTimingFunction: "ease-out",
  "&[disabled]": {
    opacity: 0.5,
    boxShadow: "none",
    pointerEvents: "none",
  },
  "&:focus": {
    boxShadow: "outline",
  },
};

export const buttons: Record<string, ThemeUIStyleObject> = {
  base,
  nav: {
    ...base,
  },
  primary: {
    ...base,
    color: "#FFF",
    background: "linear-gradient(to bottom, #D91828 0, #A1111D 100%)",
    py: "12px",
    borderRadius: "23px",
    fontSize: 1,
    fontWeight: "regular",
    width: "210px",
    letterSpacing: 0.8,
  },
  newPrimary: {
    ...base,
    fontSize: 1,
    color: "#FFF",
    bg: "#232C3A",
    borderRadius: "4px",
    fontWeight: 600,
    transition: "background 0.3s ease",
    // minWidth: 200,
    maxWidth: 308,
    width: "100%",
    "&:hover": {
      background: "brand.powderBlue",
      color: "black",
    },
  },
  newSecondary: {
    bg: "white",
    color: "black",
    borderRadius: "4px",
    transition: "background 0.3s ease",
    "&:hover": {
      background: "darkBlue",
    },
  },
  outlined: {
    bg: "transparent",
    borderColor: "darkBlue",
    border: "2px solid ",
    transition: "borderColor 0.3s ease",
    "&:hover": {
      borderColor: "powderBlue",
    },
  },
  secondary: {
    //need updates when style becomes available
    ...base,
    color: "#FFF",
    backgroundColor: "#6e6e6e",
    background: "linear-gradient(to bottom, #9e9e9e 0, #6e6e6e 100%)",
    borderTop: "solid 1px #cacaca",
    borderLeft: "solid 1px #cacaca",
    borderBottom: "solid 1px #666",
    borderRight: "solid 1px #666",
    borderRadius: "23px",
    px: "12px",
    py: "2px",
  },
  navPrimary: {
    ...base,
    color: "#FFF",
    bg: "primary",
    borderRadius: "23px",
    fontSize: 0,
    fontWeight: 600,
  },
  outline: {
    ...base,
    border: "1px solid #D91828",
    borderRadius: "23px",
    color: "primary",
    px: "50px",
  },
  apply: {
    ...base,
    backgroundColor: "primary",
    background: "-webkit-linear-gradient(top, #ed1b2e 0, #b60008 100%)",
    color: "white",
    borderRadius: "10px",
  },
};
