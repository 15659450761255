import { createContext, useReducer } from "react";
import React from "react";
import { Store } from "../types";
import { convertGps } from "../utils/utils";
import * as SearchWorker from "./search2";

export interface QueryString {
  zip: string;
}

export interface Place {
  geo: {
    latLng: google.maps.LatLng;
    latLngBounds?: google.maps.LatLngBounds;
  };
  addressComponents?: google.maps.GeocoderAddressComponent[];
  source?: string;
}

export interface FindAStoreState {
  stores: Store[];
  activeStore?: number;
  place?: Place;
  mapCenter: google.maps.LatLngLiteral;
  mapZoom: number;
  isFiltering: boolean;
  storesInView: Store[];
  noResults: boolean;
  bounds?: google.maps.LatLngBounds;
  queryString?: QueryString;
  map?: JSX.Element;
}

interface SearchWorker {
  getStoresInBounds: (
    stores: Store[],
    storesInView: Store[] | undefined,
    southWest: { lat: number; lng: number },
    northEast: { lat: number; lng: number },
    center: { lat: number; lng: number }
  ) => Promise<Store[] | false>;
}

interface SetStores {
  type: "SetStores";
  payload: Store[];
}
interface SetActiveStore {
  type: "SetActiveStore";
  payload?: number;
}
interface SetPlace {
  type: "SetPlace";
  payload?: Place;
}
interface SetNoResults {
  type: "SetNoResults";
  payload: boolean;
}
interface SetMapBounds {
  type: "SetMapBounds";
  payload: {
    zoom?: number;
    bounds?: google.maps.LatLngBounds;
    center?: google.maps.LatLng;
  };
}
interface SetQueryString {
  type: "SetQueryString";
  payload: QueryString;
}
interface SetMap {
  type: "SetMap";
  payload: {
    map: JSX.Element;
  };
}
type Actions =
  | SetStores
  | SetActiveStore
  | SetPlace
  | SetNoResults
  | SetMapBounds
  | SetQueryString
  | SetMap;

const getStoresFiltered = (
  stores: Store[],
  place?: Place
): Store[] | undefined => {
  const lat = place?.geo.latLng.lat();
  const lng = place?.geo.latLng.lng();
  if (!lat || !lng) {
    console.error(
      "No search coordinates found in the place passed to getStoresFiltered"
    );
    return undefined;
  }
  const fiftyMiles = 0.36; //converted-ish from mi to lat/lng degrees
  const center = { lat, lng };
  const bounds = {
    northEast: { lat: lat + fiftyMiles, lng: lng + fiftyMiles },
    northWest: { lat: lat + fiftyMiles, lng: lng - fiftyMiles },
    southEast: { lat: lat - fiftyMiles, lng: lng + fiftyMiles },
    southWest: { lat: lat - fiftyMiles, lng: lng - fiftyMiles },
  };

  const storesFiltered = SearchWorker.getStoresInBounds(
    stores,
    undefined,
    bounds.southWest,
    bounds.northEast,
    center
  );

  return storesFiltered || ([] as Store[]);
};
const getStoresInView = (
  mapBounds: google.maps.LatLngBounds | undefined,
  mapZoom: number,
  stores: Store[],
  storesInView: Store[]
): Store[] => {
  if (mapZoom < 7 || !mapBounds) return [];
  const southWest = {
    lat: mapBounds.getSouthWest().lat(),
    lng: mapBounds.getSouthWest().lng(),
  };
  const northEast = {
    lat: mapBounds.getNorthEast().lat(),
    lng: mapBounds.getNorthEast().lng(),
  };
  const center = {
    lat: mapBounds.getCenter().lat(),
    lng: mapBounds.getCenter().lng(),
  };
  const result = SearchWorker.getStoresInBounds(
    stores,
    storesInView,
    southWest,
    northEast,
    center
  );
  return result || storesInView;
};

const findAStoreReducer = (
  state: FindAStoreState,
  action: Actions
): FindAStoreState => {
  //console.log(action.type, action, state); //Uncomment to log all findAStoreContext updates
  switch (action.type) {
    case "SetStores": {
      const activeStore =
        !!state.activeStore &&
        action.payload?.find((s) => s.id == state.activeStore);
      return {
        ...state,
        stores: action.payload,
        mapCenter: activeStore
          ? convertGps(activeStore.gPS ?? "")
          : state.mapCenter,
        mapZoom: activeStore ? 11 : state.mapZoom,
        storesInView: getStoresInView(
          state.bounds,
          activeStore ? 11 : state.mapZoom,
          action.payload,
          state.storesInView
        ),
      };
    }
    case "SetActiveStore": {
      const activeStore =
        action.payload && state.stores?.find((s) => s.id == action.payload);
      return {
        ...state,
        activeStore: action.payload,
        mapCenter:
          activeStore && state.mapZoom < 7
            ? convertGps(activeStore.gPS ?? "")
            : state.mapCenter,
        mapZoom: activeStore && state.mapZoom < 7 ? 11 : state.mapZoom,
        place: undefined,
      };
    }
    case "SetMap":
      return {
        ...state,
        ...action.payload,
      };
    case "SetMapBounds":
      return {
        ...state,
        ...action.payload,
        storesInView: getStoresInView(
          action.payload.bounds,
          action.payload.zoom ?? 11,
          state.stores,
          state.storesInView
        ),
        isFiltering: false,
        place: state.isFiltering ? state.place : undefined,
      };
    case "SetPlace":
      return {
        ...state,
        place: action.payload,
        storesInView: getStoresFiltered(state.stores, action.payload) || [],
        mapCenter: {
          lat: action.payload?.geo.latLng.lat() ?? 0,
          lng: action.payload?.geo.latLng.lng() ?? 0,
        },
        mapZoom: 11,
        isFiltering: true,
      };
    case "SetNoResults":
      return {
        ...state,
        noResults: action.payload,
      };
    case "SetQueryString":
      return {
        ...state,
        queryString: action.payload,
      };
    default:
      return state;
  }
};

export const defaultFindAStoreState: FindAStoreState = {
  stores: [],
  storesInView: [],
  mapCenter: { lat: 38.493436, lng: -96.4842465 },
  mapZoom: 5,
  noResults: false,
  bounds: undefined,
  queryString: undefined,
  isFiltering: false,
};

interface FindAStoreValue {
  state: FindAStoreState;
  dispatch: (action: Actions) => void;
}
const FindAStoreContext = createContext<FindAStoreValue>({
  state: defaultFindAStoreState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const FindAStoreProvider: React.FC<{
  initialState?: FindAStoreState;
  children: React.ReactNode;
}> = (props) => {
  const [state, dispatch] = useReducer(findAStoreReducer, {
    ...defaultFindAStoreState,
    ...props.initialState,
  });
  return (
    <FindAStoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FindAStoreContext.Provider>
  );
};
export { FindAStoreContext, FindAStoreProvider };
