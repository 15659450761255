import * as React from 'react'
import {
  FindAStoreProvider,
  defaultFindAStoreState,
} from './src/contexts/findAStore'

// interface RootElement {
//   element: JSX.Element
// }

export const wrapRootElement = (
  { element } /*: RootElement*/,
  pluginOptions /*: ProductContextConfig*/
) => {
  const initialState = defaultFindAStoreState
  if (pluginOptions?.mapCenter) initialState.mapCenter = pluginOptions.mapCenter
  if (pluginOptions?.mapZoom) initialState.mapZoom = pluginOptions.mapZoom
  return (
    <FindAStoreProvider initialState={initialState}>
      {element}
    </FindAStoreProvider>
  )
}
